import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
import TypeWriter from 'typewriter-react'


import Inpage1 from '../assets/Images/Section2/feature/1.png'
import Inpage2 from '../assets/Images/Section2/feature/2.png'
import Inpage3 from '../assets/Images/Section2/feature/3.png'
import Inpage4 from '../assets/Images/Section2/feature/4.png'
import Inpage5 from '../assets/Images/Section2/feature/5.png'
import Inpage6 from '../assets/Images/Section2/feature/6.png'
import Inpage7 from '../assets/Images/Section2/feature/7.png'
import Inpage8 from '../assets/Images/Section2/feature/8.png'
import Inpage9 from '../assets/Images/Section2/feature/9.png'
import Inpage10 from '../assets/Images/Section2/feature/10.png'
import Inpage11 from '../assets/Images/Section2/feature/11.png'
import SideInpage from '../assets/Images/Section2/Inpage.png'
import Quran from '../assets/Images/Section2/Quran.png'
import qps from '../assets/Images/Section2/qps.png'
import zikr from '../assets/Images/Section2/zikar.png'
import arrow from '../assets/Images/Section2/arrow.png'
import Section2LeftImg from '../assets/Images/Section2/left.png'
import Section2RightImg from '../assets/Images/Section2/right.png'
import Header from './Header'


class SecondComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
      return (
         <>
            {/* <Header
               page={2}
            >
            </ Header> */}

            <div className="Section1">
               <div className="container">
                  <div className="col-md-12">
                     <div className="row">
                        <div className="col-md-6 mt-3">
                           <img className="Section2LeftImg" src={Section2LeftImg} />
                           <div className="row m-0">
                              <p className="poppins_medium mb-0 F_15">PRODUCT DISTRIBUTOR IN PAKISTAN</p>
                              <div className="br_div"></div>
                           </div>
                           <div className="col-md-12 p-0 mt-4">
                              <p className="Section2HEading">FEATURE PRODUCTS</p>
                           </div>
                           <div className="Section2_HEading3">

                           <TypeWriter
                              input={[
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                  "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "INPAGE URDU",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "QURAN PUBLISHING SYSTEM",
                                 { "pause": 8 },
                                 { "delete": 23 },
                                 "ZIKER QURAN",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "MEHR QURANIC NASKH",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                  ]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           /></div>
                           
                           <div className="col-md-12 p-0 mt-4">
                              <p className="Section2Text"> Distributing software since 1998, and a sole distributor of InPage Professional Urdu Typing Software in Pakistan, a collaborated effort of and India to promote our native languages composing work in each countries.</p>
                           </div>
                           <div className="col-md-12  p-0 mt-3">
                              <div className="row m-0 ">
                                 <div className="w-25">
                                    <img className="w-81" src={Inpage1} />
                                 </div>

                                 <div className="w-25">
                                    <img className="w-80" src={Inpage2} />
                                 </div>
                                 <div className="w-25">
                                    <img className="w-80" src={Inpage3} />
                                 </div>
                                 <div className="w-25">
                                    <img className="w-80" src={Inpage4} />
                                 </div>
                              </div>


                              <div className="row m-0 ">
                                 <div className="w-25">
                                    <img className="w-80" src={Inpage5} />
                                 </div>
                                 <div className="w-50">
                                    <img className="w-80" src={Inpage7} />
                                 </div>
                                 <div className="w-25">
                                    <img className="w-80" src={Inpage6} />
                                 </div>
                              </div>

                              <div className="row m-0 ">
                                 <div className="w-20">
                                    <img className="w-81" src={Inpage8} />
                                 </div>
                                 <div className="w-20">
                                    <img className="w-81" src={Inpage9} />
                                 </div>
                                 <div className="w-20">
                                    <img className="w-81" src={Inpage10} />
                                 </div>
                                 <div className="w-40">
                                    <img className="w-81" src={Inpage11} />
                                 </div>
                              </div>

                           </div>


                        </div>
                        <div className="col-md-6  mt-5">
                           <img className="Section2RightImg" src={Section2RightImg} />

                           <div className="row">
                              <div className="col-md-6 Section2Container mt-3">
                                 <div className="col-12 p-0">
                                    <div class="container">

                                       <img className="w-100" src={SideInpage} />

                                       <div class="overlay">
                                          <p className="sideImgText">INPAGE URDU<br></br>PUBLISHING<br></br>SOFTWARE</p>
                                          <img className="arrowIcon" src={arrow} />

                                       </div>
                                    </div>

                                 </div>


                              </div>
                              <div className="col-md-6 Section2Container  mt-3">
                                 <div className="col-12 p-0">
                                    <div class="container">

                                       <img className="w-100" src={qps} />

                                       <div class="overlay">
                                          <p className="sideImgText">QURAN <br></br>PUBLISHING<br></br>SYSTEM</p>
                                          <img className="arrowIcon" src={arrow} />

                                       </div>
                                    </div>

                                 </div>

                              </div>
                              <div className="col-md-6 Section2Container  mt-3">
                                 <div className="col-12 p-0">
                                    <div class="container">

                                       <img className="w-100" src={zikr} />

                                       <div class="overlay">
                                          <p className="sideImgText">ZEKR QURAN<br></br>PROJECT</p>
                                          <img className="arrowIcon" src={arrow} />


                                       </div>
                                    </div>

                                 </div>


                              </div>
                              <div className="col-md-6 Section2Container  mt-3">
                                 <div className="col-12 p-0">
                                    <div class="container">

                                       <img className="w-100" src={Quran} />

                                       <div class="overlay">
                                          <p className="sideImgText">MEHR QURANIC<br></br>NASKH</p>
                                          <img className="arrowIcon" src={arrow} />



                                       </div>
                                    </div>

                                 </div>


                              </div>


                           </div>

                        </div>

                     </div>

                  </div>

               </div>
            </div>

         </>


      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
SecondComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(SecondComponent);

