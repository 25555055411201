import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Logo from '../../assets/Images/Logo.svg'
//Componnet
import backgroundleft from '../../assets/Images/Section1/backgroundlreft.svg'
import Book1 from '../../assets/Images/Portfolio Books/Asset 1.png'
import Book2 from '../../assets/Images/Portfolio Books/Asset 2.png'
import Book3 from '../../assets/Images/Portfolio Books/Asset 4.png'
import Book4 from '../../assets/Images/Portfolio Books/Asset 5.png'

import Book5 from '../../assets/Images/Portfolio Books/Asset 6.png'
import Book6 from '../../assets/Images/Portfolio Books/Asset 7.png'
import Book7 from '../../assets/Images/Portfolio Books/Asset 8.png'
import Book8 from '../../assets/Images/Portfolio Books/Asset 9.png'

import Book9 from '../../assets/Images/Portfolio Books/Asset 10.png'
import Book10 from '../../assets/Images/Portfolio Books/Asset 11.png'
import Book11 from '../../assets/Images/Portfolio Books/Asset 12.png'
import Book12 from '../../assets/Images/Portfolio Books/Asset 13.png'

import Book13 from '../../assets/Images/Portfolio Books/Asset 14.png'
import Book14 from '../../assets/Images/Portfolio Books/Asset 15.png'
import Book15 from '../../assets/Images/Portfolio Books/Asset 16.png'
import Book16 from '../../assets/Images/Portfolio Books/Asset 17.png'

import Book17 from '../../assets/Images/Portfolio Books/Asset 18.png'
import Book18 from '../../assets/Images/Portfolio Books/Asset 20.png'
import Book19 from '../../assets/Images/Portfolio Books/Asset 21.png'
import Book20 from '../../assets/Images/Portfolio Books/Asset 22.png'

import Book21 from '../../assets/Images/Portfolio Books/Asset 23.png'
import Book22 from '../../assets/Images/Portfolio Books/Asset 24.png'
import Book23 from '../../assets/Images/Portfolio Books/Asset 25.png'
import Book24 from '../../assets/Images/Portfolio Books/Asset 26.png'

import Book25 from '../../assets/Images/Portfolio Books/Asset 27.png'
import Book26 from '../../assets/Images/Portfolio Books/Asset 28.png'
import Book27 from '../../assets/Images/Portfolio Books/Asset 29.png'
import Book28 from '../../assets/Images/Portfolio Books/Asset 30.png'

import Book29 from '../../assets/Images/Portfolio Books/Asset 31.png'
import Book30 from '../../assets/Images/Portfolio Books/Asset 32.png'
import Book31 from '../../assets/Images/Portfolio Books/Asset 33.png'
import Book32 from '../../assets/Images/Portfolio Books/Asset 34.png'

import Book33 from '../../assets/Images/Portfolio Books/Asset 35.png'
import Book34 from '../../assets/Images/Portfolio Books/Asset 36.png'
import Book35 from '../../assets/Images/Portfolio Books/Asset 40.png'
import Book36 from '../../assets/Images/Portfolio Books/Asset 41.png'

import Book37 from '../../assets/Images/Portfolio Books/Asset 42.png'
import Book38 from '../../assets/Images/Portfolio Books/Asset 43.png'
import Book39 from '../../assets/Images/Portfolio Books/Asset 44.png'
import Book40 from '../../assets/Images/Portfolio Books/Asset 45.png'

import Book41 from '../../assets/Images/Portfolio Books/Asset 46.png'
import Book42 from '../../assets/Images/Portfolio Books/Asset 47.png'

import stand from '../../assets/Images/OurWork/Android/bookstand.png'
import backgroundRight from '../../assets/Images/Section1/backgroundRight.svg'
import Footer from '../../component/Footer'
import Header from '../../component/Header'

class OurPublications extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      return (
         <>
            <div className=" ahmedGraphContainer">


               <Header />

               <div className="Section11">
                  <div className="container">
                     <div className="col-md-12 text-center">
                        <div className="leftBorder"></div>
                        <p className="Section3HEading">OUR WORK</p>
                        <div className="rightBorder"></div>
                        <p className="OurWorkSection3_HEading3">PUBLISHING</p>
                     </div>
                     <div className="col-md-12 mt-4 mb-4 text-center">
                        <button className="OurWorkBtn">All</button>
                        <button className="OurWorkBtn">Pre-Press Books</button>
                        <button className="OurWorkBtn">Library Books</button>
                        <button className="OurWorkBtn">Printed Books</button>
                        <button className="OurWorkBtn">Quran Work</button>

                     </div>



                     <div className="col-md-12">
                        <img className="bgleft" src={backgroundleft} />
                        <div className="row">
                           <div className="col-md-12 mt-3">
                              <div>
                                 <div className="row">
                                    <img className="w-14 im" src={Book1} />
                                    <img className="w-14 im" src={Book2} />
                                    <img className="w-14 im" src={Book3} />
                                    <img className="w-14 im" src={Book4} />
                                    <img className="w-14 im" src={Book5} />
                                    <img className="w-14 im" src={Book6} />
                                    <img className="w-14 im" src={Book7} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>
                              <div className="mt-3">
                                 <div className="row">
                                    <img className="w-14 im" src={Book8} />
                                    <img className="w-14 im" src={Book9} />
                                    <img className="w-14 im" src={Book10} />
                                    <img className="w-14 im" src={Book11} />
                                    <img className="w-14 im" src={Book12} />
                                    <img className="w-14 im" src={Book13} />
                                    <img className="w-14 im" src={Book14} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>
                              <div className="mt-3">
                                 <div className="row">
                                    <img className="w-14 im" src={Book15} />
                                    <img className="w-14 im" src={Book16} />
                                    <img className="w-14 im" src={Book17} />
                                    <img className="w-14 im" src={Book18} />
                                    <img className="w-14 im" src={Book19} />
                                    <img className="w-14 im" src={Book20} />
                                    <img className="w-14 im" src={Book21} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>
                              <div className="mt-3">
                                 <div className="row">
                                    <img className="w-14 im" src={Book22} />
                                    <img className="w-14 im" src={Book23} />
                                    <img className="w-14 im" src={Book24} />
                                    <img className="w-14 im" src={Book25} />
                                    <img className="w-14 im" src={Book26} />
                                    <img className="w-14 im" src={Book27} />
                                    <img className="w-14 im" src={Book28} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>

                              <div className="mt-3">
                                 <div className="row">
                                    <img className="w-14 im" src={Book29} />
                                    <img className="w-14 im" src={Book30} />
                                    <img className="w-14 im" src={Book31} />
                                    <img className="w-14 im" src={Book32} />
                                    <img className="w-14 im" src={Book33} />
                                    <img className="w-14 im" src={Book34} />
                                    <img className="w-14 im" src={Book35} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>

                              <div className="mt-3">
                                 <div className="row">
                                    <img className="w-14 im" src={Book36} />
                                    <img className="w-14 im" src={Book37} />
                                    <img className="w-14 im" src={Book38} />
                                    <img className="w-14 im" src={Book39} />
                                    <img className="w-14 im" src={Book40} />
                                    <img className="w-14 im" src={Book41} />
                                    <img className="w-14 im" src={Book42} />
                                 </div>
                                 <img className="StandBook" src={stand} />
                              </div>



                           </div>

                        </div>
                        <img className="bgright" src={backgroundRight} />

                     </div>

                  </div>

               </div>

               <Footer />
            </div>

         </>

      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
OurPublications.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(OurPublications);

