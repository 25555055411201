import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TypeWriter from 'typewriter-react'


import Section5LeftImage from '../assets/Images/Section5/Left.png'
import Section5RightImage from '../assets/Images/Section5/Right.png'
class FifthComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      return (
         <>

            <div className="Section1">
               <div className="container">
                  <div className="col-md-12">
                     <div className="col-md-12 text-center">
                        <div className="leftBorder"></div>
                        <p className="Section3HEading">TECHNOLOGIES</p>
                        <div className="rightBorder"></div>
                        <div className="Section5_HEading3">

                           <TypeWriter
                              input={[
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ANDROID",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "UI UX",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "REACT NATIVE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "3D MODELING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 ".NET",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                     </div>
                     <div className="col-md-12 mt-5 pb-5 mb-5 ">
                        <img className="Section3LeftImg" src={Section5RightImage} />
                        <div className="row">
                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-WordpressCard ">
                                 <div class=" wordpress-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">WORDPRESS DEVELOPMENT</p>
                              </div>

                           </div>
                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-Android-Card ">
                                 <div class=" Android-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">ANDROID DEVELOPMENT</p>
                              </div>

                           </div>

                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-ECommerce-Card ">
                                 <div class=" ECommerce-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">E-COMMERCE SOLUTIONS</p>
                              </div>

                           </div>
                           <div className="col-md-3  mt-3 mb-3" >
                              <div class="section5-UI-Card ">
                                 <div class=" UI-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">UI UX DEVELOPMENT</p>
                              </div>

                           </div>
                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-NAtive-Card ">
                                 <div class=" NAtive-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">REACT NATIVE APP</p>
                              </div>

                           </div>
                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-d3d-Card ">
                                 <div class=" d3d-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">3D MODELING</p>
                              </div>

                           </div>
                           <div className="col-md-3  mt-3 mb-3">
                              <div class="section5-net-Card ">
                                 <div class=" net-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">.NET DEVELOPMENT</p>
                              </div>

                           </div>
                           <div className="col-md-3 mt-3 mb-3">
                              <div class="section5-Web-Card ">
                                 <div class=" Web-card-background  ">
                                 </div>
                                 <p className="poppins_semibold Section5CardText">WEB DEVELOPMENT</p>
                              </div>

                           </div>

                        </div>

                        <img className="Section3RightImg" src={Section5LeftImage} />

                     </div>

                  </div>

               </div>
            </div>


         </>


      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
FifthComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(FifthComponent);

