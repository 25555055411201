import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
import TypeWriter from 'typewriter-react'

import backgroundleft from '../assets/Images/Section1/backgroundlreft.svg'
import Book1 from '../assets/Images/Books/Oxford Dictionary.png'
import Book2 from '../assets/Images/Books/AslamFarrukhiBook12.png'
import Book3 from '../assets/Images/Books/Imteyaz Quran.png'
import Book4 from '../assets/Images/Books/Asan Fehem Quran.png'
import Book5 from '../assets/Images/Books/Sadiquan12.png'
import Book6 from '../assets/Images/Books/GuestInTheHouse12.png'
import Book7 from '../assets/Images/Books/Lightstone Catalogue12.png'
import Book8 from '../assets/Images/Books/AsifSahibDuniyazad12.png'
import stand from '../assets/Images/Books/Stand.svg'
import backgroundRight from '../assets/Images/Section1/backgroundRight.svg'
import CloseBook from '../assets/Images/Section1/closebook.svg'
import android from '../assets/Images/Section1/android.svg'
import ereader from '../assets/Images/Section1/ereader.svg'
import ui from '../assets/Images/Section1/ui.svg'
import QuranKareem from '../assets/Images/Section1/QuranKareem.svg'
import InPage from '../assets/Images/Section1/InPage.svg'
import Header from './Header'
import insta from '../assets/Images/SocialMedia/insta.png'
import fb from '../assets/Images/SocialMedia/fb.png'
import linkden from '../assets/Images/SocialMedia/linkden.png'
import twiter from '../assets/Images/SocialMedia/twiter.png'



class firstComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
      return (
         <>
            <Header
               page={1}
            >
            </ Header>
            <div className="Section11">
               <div className="container">
                  <div className="col-md-12">
                     <img className="bgleft" src={backgroundleft} />
                     <div className="row">
                        <div className="col-md-6 mt-3">
                           <p className="HEading">AHMEDGRAFICS & <br></br>COMPUTER SERVICES</p>
                           <p className="HEading2">WORKING SINCE 1998</p>
                           {/* <p className="HEading3">DEVELOPMENT.</p> */}
                           <div className="HEading3">
                              <TypeWriter
                                 input={[
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                    "BOOK PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 15 },
                                    "QURAN PUBLISHING",
                                    { "pause": 8 },
                                    { "delete": 16 },
                                    "DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 11 },
                                    "DESIGNING",
                                    { "pause": 9 },
                                    { "delete": 11 },
                                    "SOFTWARE DISTRIBUTOR",
                                    { "pause": 8 },
                                    { "delete": 20 },
                                    "LICENSE URDU FONTS",
                                    { "pause": 8 },
                                    { "delete": 18 },
                                    "UI UX DESIGN",
                                    { "pause": 8 },
                                    { "delete": 12 },
                                    "EBOOK DEVELOPMENT",
                                    { "pause": 8 },
                                    { "delete": 17 },
                                 ]}

                                 speed={75}
                                 cursor={true}
                                 cursorChar="|"
                                 className="HEading3"
                              />
                           </div>

                           <div className="text-center">
                              <div className="row">
                                 {/* <div className="ImgContainer">
                                       <img className="closeBookIcon" src={CloseBook} />

                                    </div> */}
                                 <div className="col-12">
                                    <div class="section1_closebook ">
                                       <div class=" section1_closebookBg  ">
                                       </div>
                                    </div>
                                 </div>



                              </div>
                              <div className="row">
                                 <div class="section1_Inpage ">
                                    <div class=" section1_Inpagebg  ">
                                    </div>
                                 </div>
                                 <div class="section1_Quran ">
                                    <div class=" section1_Quranbg  ">
                                    </div>
                                 </div>

                              </div>
                              <div className="row">
                                 <div class="section1_UI ">
                                    <div class=" section1_UIbg  ">
                                    </div>
                                 </div>
                                 <div class="section1_android ">
                                    <div class=" section1_androidbg  ">
                                    </div>
                                 </div>
                                 <div class="section1_UII ">
                                    <div class=" section1_UIIbg  ">
                                    </div>
                                 </div>

                              </div>

                           </div>
                        </div>
                        <div className="col-md-6 mt-3">
                           <div>
                              <div className="row">
                                 <img className="w-25 im" src={Book1} />
                                 <img className="w-25 im" src={Book2} />
                                 <img className="w-25 im" src={Book3} />
                                 <img className="w-25 im" src={Book4} />

                              </div>



                              <img className="StandBook" src={stand} />
                           </div>
                           <div className="mt-3">
                              <div className="row">
                                 <img className="w-25 im" src={Book5} />
                                 <img className="w-25 im" src={Book6} />
                                 <img className="w-25 im" src={Book7} />
                                 <img className="w-25 im" src={Book8} />

                              </div>
                              <img className="StandBook" src={stand} />
                           </div>

                           <div className="text-center mt-4">
                           <Link to="/publishingwork">
                              <button className="contactBtn">See More</button></Link>
                           </div>


                        </div>
                        <div className="socialMediaStripe">
                           <a href="https://www.instagram.com/iahmedgraf/">

                              <div className="instaContainer">

                                 <img className="smediaIcons" src={insta} />

                              </div>
                           </a>
                           <a href="https://www.facebook.com/ahmedgraf">

                           <div className="fbContainer">
                              <img className="smediaIcons" src={fb} />

                           </div>
                           </a>
                           <a href="https://www.linkedin.com/company/ahmedgraf">

                           <div className="linkdenContainer">
                              <img className="smediaIcons" src={linkden} />

                           </div>
                           </a>
                           <a href="https://twitter.com/ahmedgraf">

                           <div className="twiterContainer">
                              <img className="smediaIcons" src={twiter} />

                           </div>
                           </a>

                        </div>
                     </div>
                     <img className="bgright" src={backgroundRight} />

                  </div>

               </div>

            </div>
         </>


      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
firstComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(firstComponent);

