import React, { Component } from 'react';
import { connect } from 'react-redux';
import TypeWriter from 'typewriter-react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section1/backgroundlreft.svg'

import backgroundRight from '../assets/Images/Section1/backgroundRight.svg'

//section6
import Section10TopImage from '../assets/Images/Section10/top.png'
import Section10img1 from '../assets/Images/Section10/1.png'
import Section10img2 from '../assets/Images/Section10/2.png'
import Section10img3 from '../assets/Images/Section10/3.png'
import Section10img4 from '../assets/Images/Section10/4.png'
import multi1 from '../assets/Images/Section10/multi/1.png'
import multi2 from '../assets/Images/Section10/multi/2.png'
import multi3 from '../assets/Images/Section10/multi/3.png'
import multi4 from '../assets/Images/Section10/multi/4.png'
import multi5 from '../assets/Images/Section10/multi/5.png'


import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';




class EleventhComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 4
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };
        return (
            <>
                <div className="Section mt-3 ">
                  
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-8">
                                <MultiCarousel autoPlay infiniteLoop responsive={responsive}>


                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>


                                </MultiCarousel>

                            </div>
                            <div className="col-3 vertical_center ">
                                <p className="poppins_regular s10ClientSmTxt">TRUSTED COMPANIES & PARTNERS</p>
                                <p className="poppins_medium s10ClientmdTxt"><label className="s10bd"></label>CLIENTS</p>
                                <p className="poppins_semibold s10ClientlgTxt">SHEHERZAD.</p>
                                
                            </div>

                        </div>

                    </div>

                    <div className="col-12 mt-5">
                        <div className="row">
                            <div className="col-8  text-center">
                             <p className="poppins_medium s10lastText mb-0">LOOKING FOR</p>
                             <p className="poppins_medium s10lastText mb-0"><label className="poppins_bold s10service mb-0">SERVICES  </label> THAT</p>
                             <p className="poppins_medium s10lastText mb-0">WE PROVIDE?</p>

                            </div>
                            <div className="col-3 vertical_center ">
                              <button className="s10Btnn">GET IN TOUCH</button>

                            </div>

                        </div>

                    </div>

                </div>



            </>


        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
EleventhComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(EleventhComponent);

