import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LgsLogo from '../../assets/Images/OurWork/UIUX/LGS/lgslogo.png'
import LgsScreen from '../../assets/Images/OurWork/UIUX/LGS/LGS.png'
import RHCONSLT from '../../assets/Images/OurWork/UIUX/LGS/RHCONSLT.png'
import RHCONST from '../../assets/Images/OurWork/UIUX/LGS/RHCONST.png'

import branding from '../../assets/Images/OurWork/UIUX/LGS/branding.png'
import background from '../../assets/Images/OurWork/UIUX/LGS/background.png'
import EleventhComponent from '../../component/EleventhComponent'

import icon1 from '../../assets/Images/OurWork/UIUX/LGS/icons/1.png'
import icon2 from '../../assets/Images/OurWork/UIUX/LGS/icons/2.png'
import icon3 from '../../assets/Images/OurWork/UIUX/LGS/icons/3.png'
import icon4 from '../../assets/Images/OurWork/UIUX/LGS/icons/4.png'
import icon5 from '../../assets/Images/OurWork/UIUX/LGS/icons/5.png'
import icon6 from '../../assets/Images/OurWork/UIUX/LGS/icons/6.png'
import icon7 from '../../assets/Images/OurWork/UIUX/LGS/icons/7.png'
import icon8 from '../../assets/Images/OurWork/UIUX/LGS/icons/8.png'
import icon9 from '../../assets/Images/OurWork/UIUX/LGS/icons/9.png'
import icon10 from '../../assets/Images/OurWork/UIUX/LGS/icons/10.png'
import icon11 from '../../assets/Images/OurWork/UIUX/LGS/icons/11.png'
import icon12 from '../../assets/Images/OurWork/UIUX/LGS/icons/12.png'
import icon13 from '../../assets/Images/OurWork/UIUX/LGS/icons/13.png'
import icon14 from '../../assets/Images/OurWork/UIUX/LGS/icons/14.png'
import app1 from '../../assets/Images/OurWork/UIUX/LGS/1.png'
import app2 from '../../assets/Images/OurWork/UIUX/LGS/2.png'
import app3 from '../../assets/Images/OurWork/UIUX/LGS/3.png'
import app4 from '../../assets/Images/OurWork/UIUX/LGS/4.png'

import fullscreen1 from '../../assets/Images/OurWork/UIUX/LGS/userinterface/fullscreen1.png'
import fullscreen2 from '../../assets/Images/OurWork/UIUX/LGS/userinterface/fullscreen2.png'

import Footer from '../../component/Footer'
import Header from '../../component/Header'
class UIUX extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className=" ahmedGraphContainer">

                    <Header />


                    <div className="Section11">
                        <div className="container">
                            <div className="col-md-12 mb-5 text-center">
                                <div className="leftBorder"></div>
                                <p className="Section3HEading">OUR WORK</p>
                                <div className="rightBorder"></div>
                                <p className="OurWorkSection3_HEading3">UI UX DESIGN</p>
                            </div>
                            <div className="col-md-12 mt-5 mb-4 text-center">
                                <button className="OurWorkBtnlgs">Local Grocery</button>
                                <button className="OurWorkBtnlgs">On Road 24 Seven</button>
                                <button className="OurWorkBtnlgs">TutorBay</button>
                                <button className="OurWorkBtnlgs">89 Designs</button>
                                <button className="OurWorkBtnlgs">SalonHub.pk</button>
                                <button className="OurWorkBtnlgs">Little Book Compnay</button>



                            </div>
                            <div className="col-md-12 mt-5">
                                <div className="row">
                                    <div className="col-md-6 mt-5">
                                        <img className="LGSLOGO mt-3 mb-3" src={LgsLogo} />
                                        <p className="poppins_bold lgsText">Multi-Platform Application</p>
                                        <p className="poppins_bold LGsHeading mb-0">Local Grocery's  </p>
                                        <p className="poppins_regular lgsText">An eCommerce platform for grocery, <br></br>
                                        including merchant panel, admin panel, <br></br>
                                        mobile app, website & rider app</p>
                                        <img className="rhconslt" src={RHCONSLT} />
                                    </div>
                                    <div className="col-md-6 mt-5">
                                        <img className="LGsScreen" src={LgsScreen} />

                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12 uiSection2 mt-5">
                                <p className="poppins_semibold">Challenges</p>
                                <p className="poppins_semibold uiSection2Text">What challenges have I encountered?</p>
                                <div className="col-md-12 mt-5">
                                    <div className="row">
                                        <div className="col-md-4 p-5 ">
                                            <button className="uiSection2btn">01</button>
                                            <p className="poppins_medium uiSection2Text1 mt-5">A complete user journey from start to end including via framing and prototyping.</p>
                                        </div>
                                        <div className="col-md-4 p-5 ">
                                            <button className="uiSection2btn">02</button>
                                            <p className="poppins_medium uiSection2Text1 mt-5">Build scenarios according to user survey and enhancing the user experience based on discussion. </p>

                                        </div>
                                        <div className="col-md-4 p-5 ">
                                            <button className="uiSection2btn">03</button>
                                            <p className="poppins_medium uiSection2Text1 mt-5">Branding and Merchandising of Local Grocery's.  Using minimum information.</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="col-md-12 mt-5">
                                <div className="row">
                                    <div className="col-md-5 mt-5">
                                        <img className="LGSLOGO mt-3 mb-3" src={LgsLogo} />
                                        <p className="poppins_medium uisection3Text">Make grocery shopping easier</p>

                                    </div>
                                    <div className="col-md-7 mt-5">
                                        <p className="poppins_bold uisection3heading">Project</p>
                                        <p className="poppins_bold uisection3MainHEading">Local Grocery</p>
                                        <p className="poppins_regular uisection3f20">An online grocery shopping store, an American base
                                        startup, to sell grocery and deliver online. Includes
                                        merchant management, admin monitoring, eCommerce
                                        website, mobile app for customer and rider app.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 mt-5">
                                        <p className="poppins_bold uisection3heading">Client</p>
                                        <p className="poppins_bold uisection3MainHEading">RH3 Consulting</p>
                                        <p className="poppins_regular uisection3f20">RH³ Consulting has been the preliminary
                                        selection for many startups to huge businesses.
                                        They take their assessment as their best decision
                                        so easily as of the comprehensive groundwork,matchless ideas, next-generation philosophy</p>
                                    </div>

                                    <div className="col-md-7 text-center uisection3rhconslt">
                                        <img className="uisection3rhconsltlogo" src={RHCONST} />

                                    </div>
                                </div>


                            </div>



                        </div>

                        <div className="UIUxsection4">
                            <p className="poppins_semibold f20">Visual design</p>
                            <p className="poppins_bold f35">Visual style devised for the platform</p>
                            <p className="f16 poppins_regular mt-4">
                                <label className="ml-3 mr-3 UIUxsection4platform">Branding </label>
                                <label className="ml-3 mr-3">/</label>
                                <label className="ml-3 mr-3 UIUxsection4platform">Typography</label>
                                <label className="ml-3 mr-3">/</label>
                                <label className="ml-3 mr-3 UIUxsection4platform">Colors</label>
                                <label className="ml-3 mr-3">/</label>
                                <label className="ml-3 mr-3 UIUxsection4platform">Iconography </label>
                                <label className="ml-3 mr-3">/</label>
                                <label className="ml-3 mr-3 UIUxsection4platform"> UI Designs</label>

                            </p>
                        </div>
                        <div className="container">
                            <div className="col-md-12 mb-5 mt-5">
                                <p className="poppins_bold brandingheading">Branding</p>

                                <div className="row">
                                    <div className="col-md-7 vertical_center">
                                        <img className="LGSLOGO1 mt-3 mb-3" src={LgsLogo} />
                                        <p className="poppins_medium uisection3Text">Make grocery shopping easier</p>

                                    </div>
                                    <div className="col-md-5 mt-5">
                                        <img className="w-100 uiSection5branding" src={branding} />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12 mt-5">
                                <img className="uibackgorund" src={background} />

                                <div className="row">
                                    <div className="col-md-4  ">
                                        <p className="poppins_bold brandingheading mt-5">Typography</p>

                                        <p className="LgsPrimaryClr Montserrat1">Montserrat</p>
                                        <p className=" Montserrat2">Aa</p>
                                        <p className="LgsPrimaryClr Montserrat3">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat4">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat5">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat6">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat7">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat8">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat9">Montserrat</p>
                                        <p className="LgsPrimaryClr Montserrat10">Montserrat</p>


                                    </div>
                                    <div className="col-md-1  "></div>

                                    <div className="col-md-7 ">
                                        <p className="poppins_bold brandingheading mt-5">Colors</p>
                                        <div className="col-md-12 colors mt-5">

                                            <div className="row mt-5">
                                                <div className="col-md-4 text-center">
                                                    <div className="orange"></div>
                                                    <p className="poppins_medium mb-0 mt-3">Tree Poppy</p>
                                                    <p className="poppins_light">#F69522</p>

                                                </div>
                                                <div className="col-md-4  text-center">
                                                    <div className="green"></div>
                                                    <p className="poppins_medium mb-0 mt-3">Eucalyptus</p>
                                                    <p className="poppins_light">#23934C</p>
                                                </div>
                                                <div className="col-md-4  text-center">
                                                    <div className="pink"></div>
                                                    <p className="poppins_medium mb-0 mt-3">Carnation</p>
                                                    <p className="poppins_light">#F16262</p>
                                                </div>



                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-md-4 text-center">
                                                    <div className="white"></div>
                                                    <p className="poppins_medium mb-0 mt-3">White</p>
                                                    <p className="poppins_light">#FFFFFF</p>

                                                </div>
                                                <div className="col-md-4  text-center">
                                                    <div className="black"></div>
                                                    <p className="poppins_medium mb-0 mt-3">Dune</p>
                                                    <p className="poppins_light">#2D2926</p>
                                                </div>
                                                <div className="col-md-4  text-center">
                                                    <div className="blue"></div>
                                                    <p className="poppins_medium mb-0 mt-3">East Bay</p>
                                                    <p className="poppins_light">#3F4185</p>
                                                </div>



                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12 mb-5 mt-5">
                                <p className="poppins_bold brandingheading">Iconography</p>

                                <div className="row mt-5 mb-5">
                                    <div className="icondiv">
                                        <img className="icon" src={icon1} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon2} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon3} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon4} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon5} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon6} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon7} />

                                    </div>




                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="icondiv">
                                        <img className="icon" src={icon8} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon9} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon10} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon11} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon12} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon13} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon14} />

                                    </div>




                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="icondiv">
                                        <img className="icon" src={icon1} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon2} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon3} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon4} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon5} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon6} />

                                    </div>
                                    <div className="icondiv">
                                        <img className="icon" src={icon7} />

                                    </div>




                                </div>

                            </div>

                            <div className="col-md-12 userinterface mb-5 mt-5">
                                <p className="poppins_bold heading">User Interface</p>
                                <p className="poppins_bold Mainheading">Website UI Design </p>
                                <img className="fullscreen1" src={fullscreen1} />
                                <img className="fullscreen2" src={fullscreen2} />

                            </div>


                            <div className="col-md-12 userinterface mb-5 mt-5">
                                <div className="row">
                                    <div className="col-md-3">
                                        <img className="w-100" src={app1}/>

                                    </div>
                                    <div className="col-md-3">
                                    <img className="w-100" src={app2}/>

                                    </div>
                                    <div className="col-md-3">
                                    <img className="w-100" src={app3}/>

                                    </div>
                                    <div className="col-md-3">
                                    <img className="w-100" src={app4}/>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <EleventhComponent />


                    </div>


                    <Footer />
                </div>

            </>

        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
UIUX.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(UIUX);

