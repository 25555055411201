import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section4/redleft.png'

import backgroundRight from '../assets/Images/Section4/redright.png'
import TypeWriter from 'typewriter-react'
//section6
import Section6book1 from '../assets/Images/Section6/1.png'
import Section6book2 from '../assets/Images/Section6/2.png'
import Section6book3 from '../assets/Images/Section6/3.png'
import Section6book4 from '../assets/Images/Section6/4.png'
import Section6book5 from '../assets/Images/Section6/5.png'


import ux from '../assets/Images/Section8/ux.png'
import computer from '../assets/Images/Section8/computer.png'
import dashboard from '../assets/Images/Section8/dashboard.png'
import scenario from '../assets/Images/Section8/scenario.png'
import s8img from '../assets/Images/Section8/s8img.png'



class EightComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {
     
   }
  

 

   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
     
      return (
        <>
  <div  id="Design" className="Section1 Section8Container">
                  <div className="container">
                     <div className="col-md-12 p-0">
                        <img className="S8bgleft" src={backgroundleft} />
                        <div className="row">
                           <div className="col-md-6 mt-5">
                              <p className="HEading">AHMEDGRAFICS & <br></br>COMPUTER SERVICES</p>
                              <p className="HEading2">WORKING SINCE 1998</p>
                              <div className="Section4HEading3">

                           <TypeWriter
                              input={[
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "UX PRINCIPLE",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "USER STORIES",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "WIRE FRAMING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROTOTYPING",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "RESPONSIVE UI",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "DESIGN SPRINT",
                                 { "pause": 8 },
                                 { "delete": 13 },
]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                              <div className="col-md-12 mt-5">
                                 <div className="row mt-5">
                                    <div className="col-md-6 col-6 section8_text_right ">
                                    <button className="s8btn"><img  className=" s8w-65" src={ux}/></button>
                                    <p className="s8btnText">UX PRINCIPLE</p>

                                    </div>
                                    <div className="col-md-5 col-6 section8_text_right">
                                    <button className="s8btn"><img className=" s8w-65" src={computer}/></button>
                                    <p className="s8btnText">RESPOSNIVE UI</p>

                                      
                                    </div>

                                 </div>


                                 <div className="row mt-5">
                                    <div className="col-md-4 col-6  section8_text_right">
                                    <button className="s8btn"><img className=" s8w-65" src={dashboard}/></button>
                                    <p className="s8btnText">USER STORY</p>

                                    </div>
                                    <div className="col-md-5 col-6 section8_text_right ">

                                    <button className="s8btn"><img className=" s8w-65" src={scenario}/></button>
                                    <p className="s8btnText">DESIGN SPRINT</p>

                                    </div>

                                 </div>

                               
                              </div>

                           </div>
                           <div className="col-md-6 text-center s8Mt">
                             <img className="w-100 s8IMG" src={s8img}/>

                           </div>
                        </div>
                        <img className="S8bgright" src={backgroundRight} />

                     </div>

                  </div>

               </div>
              


        </>
       
     
     )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
  
})
EightComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(EightComponent);

