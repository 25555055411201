import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';


import Logo from '../../assets/Images/Logo.svg'
import backgroundleft from '../../assets/Images/Section1/backgroundlreft.svg'

import backgroundRight from '../../assets/Images/Section1/backgroundRight.svg'
//Componnet
import FirstComponent from '../../component/firstComponent'
import SecondComponent from '../../component/SecondComponent'
import ThirdComponent from '../../component/ThirdComponent'
import FourthComponent from '../../component/FourthComponent'
import FifthComponent from '../../component/FifthComponent'
import SixthComponent from '../../component/SixthComponent'
import SeventhComponent from '../../component/SeventhComponent'
import EightComponent from '../../component/EightComponent'
import NineComponent from '../../component/NineSection'
import TenComponent from '../../component/TenComponent'
import Footer from '../../component/Footer'
import Header from '../../component/Header'

import publish from '../../assets/Images/publish.png'
import ui from '../../assets/Images/ui.png'
import web from '../../assets/Images/web.png'
import app from '../../assets/Images/app.png'


class Dashboard extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      return (
         <>
            <div className=" ahmedGraphContainer">

               {/* <div className="col-md-12 Header pt-3 ">
                  <div className="row">
                     <div className="col-md-3 Vertical_center">
                        <img className="Logo-Img" src={Logo} />
                     </div>
                     <div className="col-md-9 Vertical_center">
                        <div className="row">
                           <div className="header-contain">

                              <p className="poppins_medium headerActive f_14" >Overview</p>
                           </div>

                           <div className="header-contain">

                              <p className="poppins_medium headernotActive f_14" >Development</p>
                           </div>
                           <div className="header-contain">

                              <p className="poppins_medium headernotActive f_14" >Design</p>
                           </div>
                           <div className="header-contain">

                              <p className="poppins_medium headernotActive f_14" >Publishing </p>
                           </div>
                           <div className="header-contain">

                              <p className="poppins_medium headernotActive f_14" >Products</p>
                           </div>
                           <div className="header-contain">

                              <p className="poppins_medium headernotActive f_14" >Our Store</p>
                           </div>
                           <div className="header-contain">

                              <p className="mb-0  poppins_medium headernotActive f_14" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" data-toggle="collapse">
                                 Our Work</p>

                           </div>
                           <div className="header-contain-btn">
                              <button className="contactBtn">Contact Us</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="collapse" id="collapseExample">
                     <div class="card card-body">
                        <div className="container">
                           <div className="col-md-3">
                           <Link to="/">

                              <p className="navText"><img className="CardTextImg" src={publish}/>PUBLISHING WORK</p>
                         </Link>
                           </div>
                           <div className="col-md-3">
                           <Link to="/android">

                              <p className="navText"><img className="CardTextImg" src={app}/>APPLICATION WORK</p>
                        </Link>
                           </div>
                           <div className="col-md-3">
                           <Link to="/woocommerce">

                              <p className="navText"><img className="CardTextImg" src={web}/>WEBSITE DEVELOPMENT</p>
                          </Link>
                           </div>
                           <div className="col-md-3">
                           <Link to="/">

                              <p className="navText"><img className="CardTextImg" src={ui}/>UI UX  DESIGNING</p>
                       </Link>
                           </div>
                        </div>
                     </div>
                  </div>

               </div> */}
               <FirstComponent />
               <SecondComponent />
               <ThirdComponent />
               <FourthComponent />
               <FifthComponent />
               <SixthComponent />
               <SeventhComponent />
               <EightComponent />
               <NineComponent />
               <TenComponent />
               <Footer />
            </div>

         </>

      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
Dashboard.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);

