import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section1/backgroundlreft.svg'

import backgroundRight from '../assets/Images/Section1/backgroundRight.svg'
import LeftLogo from '../assets/Images/Section10/left.png'
import rightLogo from '../assets/Images/Section10/right.png'
import TypeWriter from 'typewriter-react'


//section6
import Section10TopImage from '../assets/Images/Section10/top.png'
import Section10img1 from '../assets/Images/Section10/1.png'
import Section10img2 from '../assets/Images/Section10/2.png'
import Section10img3 from '../assets/Images/Section10/3.png'
import Section10img4 from '../assets/Images/Section10/4.png'
import multi1 from '../assets/Images/Section10/multi/1.png'
import multi2 from '../assets/Images/Section10/multi/2.png'
import multi3 from '../assets/Images/Section10/multi/3.png'
import multi4 from '../assets/Images/Section10/multi/4.png'
import multi5 from '../assets/Images/Section10/multi/5.png'

import { Modal } from 'react-bootstrap';

import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';




class TenComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };
        return (
            <>
                <div className="Section1 ">
                    <div className="container">
                        <div className="col-md-12 p-0">
                            <img className="bgleft" src={backgroundleft} />
                            <div className="col-md-12 ">

                            <div className="row m-0">
                              <p className="poppins_medium mb-0 F_15">PRODUCT DISTRIBUTOR IN PAKISTAN</p>
                              <div className="br_div"></div>
                              </div>
                           </div>
                           <div className="col-md-12  mt-2">
                              <p className="Section2HEading">FEATURE PRODUCTS</p>
                              <p className="Section2_HEading3">INPAGE</p>
                           </div>
                           
                            <div className="row">

                                <div className="col-md-6 mt-5">

                                    <div className="col-md-12 p-0">
                                        <img className="w-100 s10topimg s10br6" src={Section10TopImage} />

                                    </div>
                                    <div className="col-md-12 p-0 mt-5">
                                        <div className="row m-0">
                                            <div className="col-3">
                                                <img className="w-100 s10br6 " src={Section10img1} />
                                            </div>
                                            <div className="col-3">
                                                <img className="w-100  s10br6" src={Section10img2} />

                                            </div>
                                            <div className="col-3">
                                                <img className="w-100 s10br6 " src={Section10img3} />

                                            </div>
                                            <div className="col-3">
                                                <img className="w-100 s10br6 " src={Section10img4} />

                                            </div>

                                        </div>



                                    </div>

                                </div>
                                {/* <div className="col-md-6 mt-5  ">
                                    <p className="poppins_semibold s10heading">INPAGE URDU TYPING SOFTWARE</p>
                                    <p className="poppins_regular s10text">InPage™ Urdu is the industry standard tools for page-making of Newspapers, Magazines & Books in Urdu/Arabic languages. Using the power of Nastaliq & Character and Faiz Nastaliq based fonts InPage™ gives you the freedom to design your dreams in almost all Perso-Arabic scripts like Urdu, Arabic, Persian, Sindhi, Kashmiri Pushto & Hazaragi.</p>

                                </div> */}
                                <div className="col-md-6 mt-5  ">
                                    <p className="poppins_semibold s10heading">INPAGE URDU TYPING SOFTWARE</p>
                                    <p className="poppins_regular s10text">InPage™ Urdu is the industry standard tools for page-making of Newspapers, Magazines & Books in Urdu/Arabic languages. Using the power of Nastaliq & Character and Faiz Nastaliq based fonts InPage™ gives you the freedom to design your dreams in almost all Perso-Arabic scripts like Urdu, Arabic, Persian, Sindhi, Kashmiri Pushto & Hazaragi.</p>

                                    <p className="poppins_semibold s10heading mt-3">LATEST SOFTWARE VERSION</p>
                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">InPage Version</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">3.6xx version <b>( Offline with Dongle, Online Version, Network Version)</b></p>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">Supports</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">Windows (XP, 7, 8, 10 and Windows Server) and MAC</p>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">Includes</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">Box Pack with CD and USB Dongle</p>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">Network Version</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">Available up to five (5) Users</p>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">Documentation</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">Download PDF Manual</p>
                                        </div>

                                    </div>

                                    <div className="row ">
                                        <div className="col-3">
                                            <p className="poppins_semibold s10text">About</p>

                                        </div>
                                        <div className="col-9">
                                            <p className="poppins_regular s10text">Sole Distributors in Pakistan</p>
                                        </div>

                                    </div>

                                    <div className="text-right">
                                        <button className="s10btn">Get a Qoute</button>

                                    </div>
                                </div>
                            </div>
                            <img className="bgright" src={backgroundRight} />

                        </div>




                    </div>
                    <div className="col-12 mt-4">
                        <div className="row">
                        <img className="S10bgleft" src={LeftLogo} />

                            <div className="col-8">
                                <MultiCarousel autoPlay infiniteLoop responsive={responsive}>


                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>

                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi1} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi2} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi3} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100 mt-3" src={multi4} />

                                        </div>

                                    </div>
                                    <div className="pl-3 pr-3">
                                        <div className="Client-Card">
                                            <img className="w-100" src={multi5} />

                                        </div>

                                    </div>


                                </MultiCarousel>

                            </div>
                            <div className="col-3 vertical_center ">
                                <p className="poppins_regular s10ClientSmTxt">TRUSTED COMPANIES & PARTNERS</p>
                                <p className="poppins_medium s10ClientmdTxt"><label className="s10bd"></label>CLIENTS</p>
                                <div className="poppins_semibold s10ClientlgTxt">
                           <TypeWriter
                              input={[
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "LIGHTSTONE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "SHERZADE",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "OXFORD",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "FAZLEE",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "JANG",
                                 { "pause": 8 },
                                 { "delete": 4 },
                                 "TELENOR",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "MEHFOOZ BOOK",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "ANJUMAN URDU",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "DARUL ULOOM",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "URDU SPACE",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "CONCEPT",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "JANG NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "UMAT NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "USAF NEW",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "EXPRESS NEW",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "NAWA E WAQT",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "DUNIYA NEW",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ABBOTT",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "RECKITT BENCHKISTER",
                                 { "pause": 8 },
                                 { "delete": 19 },
                                 "SYNGENTA",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "ENGRO CORP",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "KAPCO",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "PHILIPS",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "FFC",
                                 { "pause": 8 },
                                 { "delete": 3 },
                                 "CITY PRESS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WORLD BANK",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "DOLLER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "AGHA KHAN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "ICI PAINTS",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "ASKARI BANK",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "PAKISTAN TOBACCO",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "SALON HUB",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "RH CUBE",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "ICARE",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "MEDMASK",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "INTERIOCHIC",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                ]}
                                speed={75}
                                cursor={true}
                                cursorChar="|"
                                className="HEading3"
                             />
                                                       </div>
                            </div>
                            <img className="S10bgright" src={rightLogo} />

                        </div>

                    </div>

                    <div className="col-12 mt-5">
                        <div className="row">
                            <div className="col-8  text-center">
                             <p className="poppins_medium s10lastText mb-0">LOOKING FOR</p>
                             <p className="poppins_medium s10lastText mb-0"><label className="poppins_bold s10service mb-0">SERVICES  </label> THAT</p>
                             <p className="poppins_medium s10lastText mb-0">WE PROVIDE?</p>

                            </div>
                            <div className="col-3 vertical_center ">
                              <button className="s10Btnn">GET IN TOUCH</button>

                            </div>

                        </div>

                    </div>

                </div>



            </>


        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
TenComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(TenComponent);

