import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Logo from '../../assets/Images/Logo.svg'
//Componnet

import backgroundleft from '../../assets/Images/Section1/backgroundlreft.svg'

import backgroundRight from '../../assets/Images/Section1/backgroundRight.svg'

//section6
import Section10TopImage from '../../assets/Images/Section10/top.png'
import Section10img1 from '../../assets/Images/Section10/1.png'
import Section10img2 from '../../assets/Images/Section10/2.png'
import Section10img3 from '../../assets/Images/Section10/3.png'
import Section10img4 from '../../assets/Images/Section10/4.png'
import hrmataesimg from '../../assets/Images/products/HRMATESN.JPG'
import jewelmesh from '../../assets/Images/products/jewelmesh.jpg'
import QPS from '../../assets/Images/products/QPS.jpg'
import QPS1 from '../../assets/Images/products/QPS1.jpg'
import QPS2 from '../../assets/Images/products/QPS2.jpg'
import QPS3 from '../../assets/Images/products/QPS3.jpg'
import QPS4 from '../../assets/Images/products/QPS4.jpg'
import zekr from '../../assets/Images/products/zekr.png'
import zekr1 from '../../assets/Images/products/zekr1.jpg'
import zekr2 from '../../assets/Images/products/zekr2.jpg'
import zekr3 from '../../assets/Images/products/zekr3.jpg'
import zekr4 from '../../assets/Images/products/zekr4.jpg'
import mehrnakash from '../../assets/Images/products/mehrnakash.jpg'
import mehrnakash1 from '../../assets/Images/products/mehrnaksh1.jpg'
import mehrnakash2 from '../../assets/Images/products/meharnaksh2.jpg'
import mehrnakash3 from '../../assets/Images/products/meharnaksh3.jpg'
import mehrnakash4 from '../../assets/Images/products/meharnaksh4.jpg'


import Qalam from '../../assets/Images/products/Qalam.gif'
import multi5 from '../../assets/Images/Section10/multi/5.png'

import Footer from '../../component/Footer'
import Header from '../../component/Header'

import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class Publishing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className=" ahmedGraphContainer">


                    <Header />

                    <div className="Section11 ">
                        <div className="container">
                            <div className="col-md-12 p-0">
                                <img className="bgleft" src={backgroundleft} />
                                {/* <div className="col-md-12 mt-5 pt-5">
                                    <p className="HEading">OUR PRODUCTS</p>
                                    <p className="Section4HEading3 Section10HEading3">INPAGE.</p>
                                </div> */}
                                <div className="col-md-12 mb-5 text-center">
                                <div className="leftBorder"></div>
                                <p className="Section3HEading">OUR PRODUCTS</p>
                                <div className="rightBorder"></div>
                                <p className="OurWorkSection3_HEading3">INPAGE</p>
                            </div>
                          
                                <div className="row">

                                    <div className="col-md-6 mt-0 ">

                                        <div className="col-md-12 p-0">
                                            <img className="w-100 s10topimg s10br6" src={Section10TopImage} />

                                        </div>
                                        {/* <div className="col-md-12 p-0 mt-5 pt-5">
                                            <div className="row m-0">
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 " src={Section10img1} />
                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100  s10br6" src={Section10img2} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 " src={Section10img3} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 " src={Section10img4} />

                                                </div>

                                            </div>



                                        </div> */}

                                    </div>

                                    <div className="col-md-6 mt-1  ">
                                        <p className="poppins_semibold s10heading">INPAGE URDU PUBLISHING SOFTWARE</p>
                                        <p className="poppins_regular s10text">InPage™ Urdu is the industry standard tools for page-making of Newspapers, Magazines & Books in Urdu/Arabic languages. Using the power of Nastaliq & Character and Faiz Nastaliq based fonts InPage™ gives you the freedom to design your dreams in almost all Perso-Arabic scripts like Urdu, Arabic, Persian, Sindhi, Kashmiri Pushto & Hazaragi.</p>

                                        <p className="poppins_semibold s10heading mt-3">LATEST SOFTWARE VERSION</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">InPage Version</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">3.6xx version <b>(Offline with Dongle, Online Version, Network Version)</b></p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Windows (XP, 7, 8, 10 and Windows Server) and MAC</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Box Pack with CD and USB Dongle</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Network Ver.</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Available up to five (5) Users</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">More Info.</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="http://www.inpage.com/">
                                                <p className="poppins_regular s10text">Visit InPage Website</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Sole Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">HR-MATES ERP MANAGEMENT SOFTWARE</p>
                                        <p className="poppins_regular s10text">HRMates – HR & Payroll Automation Platform is an Employee Self Service, Workflow oriented Collaborative platform for Managers, Employees and Company to manage their HR and Payroll related activities. HRMates automates HR Policies of the organization very closely and provides flexibility for the policies to be changed over time.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Solution and Module</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Foundation, Talent Management, Employee Expenses, Employee Engagement, Hiring, Payroll, Employee Monitoring, Training, Rewards and Recognition, Documents, Functionality, Pricing Model, Engagement Model & Integration  <b>(Microsoft Teams and Slack)</b></p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Web, Desktop & Mobile accessibility.</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">License & 24 hours’ support</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">More Info.</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="https://www.hrmates.com/">
                                                <p className="poppins_regular s10text">Visit HRMates Website</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Sole Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center mt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className="w-100 s10topimg s10br6" src={hrmataesimg} />

                                        </div>

                                    </div>


                                    <div className="col-md-6 mt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className="w-100 s10topimg s10br6" src={jewelmesh} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">JEWELMESH – JEWELERY MANAGEMENT SOFTWARE</p>
                                        <p className="poppins_regular s10text">JewelMesh is an application designed from ground up for Jewelers. The development of the product has been done with active input from jeweler industry to incorporate the time tested business processes followed by jeweler industry. JewelMesh is focused on solving the problems in jeweler retail industry in a way that the interface is kept simple and minimal.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Solution and Module</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Reporting, Sales Invoice, Retails, Wholesale, Manufacturer, Barcode tags, RFID, Images Catalogue, Multi store support & Stock management</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Web Solution</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">License and 24 hours’ support</p>
                                            </div>

                                        </div>
                                        
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">More Info.</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="http://www.jewelmesh.com/">
                                                <p className="poppins_regular s10text">Visit JewelMesh Website</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Sole Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">QURAN PUBLISIHNG SYSTEM - QPS</p>
                                        <p className="poppins_regular s10text">Qur’an Publishing System provides an efficient WYSIWYG (what you see is what you get) solution for printing Qur’an with Qur’anic text, translation and tafseer. It provides industrial standard digital and advance printing facilities as opposed to the conventional methodologies of Qur’an Publications with added support to all Asian, European, sub-continental and international languages without the distinction of pattern. The Qur’an data incorporated in the software is 100% verified and non-editable.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Current Version</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">2.6xx version <b>(Offline with Dongle)</b></p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Windows (XP, 7, 8, 10 and Windows Server)</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Box Pack with CD and USB Dongle</p>
                                            </div>

                                        </div>
                                        
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">More Info.</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="http://www.inpage.com/Home/QPS">
                                                <p className="poppins_regular s10text">Visit PQS Website</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Sole Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>
                                    <div className="col-md-6  mt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className=" s10topimg2 w-100 s10br6" src={QPS} />
                                        </div>
                                        {/* <div className="col-md-12 p-0 mt-5 pt-5">
                                            <div className="row m-0">
                                                <div className="col-3">
                                                    <img className="w-100 s10br6  qpsh" src={QPS1} />
                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100  qpsh s10br6" src={QPS2} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={QPS3} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={QPS4} />

                                                </div>

                                            </div>



                                        </div> */}


                                    </div>


                                    <div className="col-md-6 mt-5 pt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className="w-100 s88topimg s10br6" src={zekr} />

                                        </div>
                                        {/* <div className="col-md-12 p-0 mt-5 pt-5 pt-5">
                                            <div className="row m-0">
                                                <div className="col-3">
                                                    <img className="w-100 s10br6  qpsh" src={zekr1} />
                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100  qpsh s10br6" src={zekr2} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={zekr3} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={zekr4} />

                                                </div>

                                            </div>



                                        </div> */}


                                    </div>

                                    <div className="col-md-6 mt-5 pt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">ZEKR QURAN PROJECT – INDO PAK VERSION</p>
                                        <p className="poppins_regular s10text">Zekr Qur’an was made with coding made for Windows, giving it the feel of any other Windows application. It is fully featured with advanced searching, navigation, recitation, and commentary. The backbone of Zekr is very generic, allowing customization in many ways. Customize the language, translation, recitation, commentary, and theme. Make Zekr perfect for you.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Current Version</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">1.1xx version</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Windows (XP, 7, 8, 10 and Windows Server)</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Installation Guide</p>
                                            </div>

                                        </div>
                                        
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Type</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="">
                                                <p className="poppins_regular s10text">Free Download</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Available</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-5 pt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">QALAM BARTAR</p>
                                        <p className="poppins_regular s10text">Typography and play with letters are a unique feature of the Persian line that helps to make the text more beautiful. The Qalam Bartar typography tool can be used directly in Word, Indesign, Illustrator, PowerPoint, Wordpad, Notpad. You’ll see shortcut keys and their performance.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Current Version</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Latest Updated License</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Windows & MAC</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Installation Guide & Support</p>
                                            </div>

                                        </div>
                                        
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">More Info.</p>

                                            </div>
                                            <div className="col-9">
                                                <a href="https://drive.google.com/file/d/18vahcwwz0VxseJJ4iv1zbwJ_4K6MF5oS/view?usp=sharing">
                                                <p className="poppins_regular s10text">See Documentation</p></a>
                                            </div>

                                        </div>

                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>


                                        {/* <div className="text-right">
                                        <button className="s10btn">Get a Qoute</button>

                                    </div> */}
                                    </div>
                                    <div className="col-md-6  text-center mt-5 pt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className=" s10topimg3  s10br6" src={Qalam} />
                                        </div>
                                        {/* <div className="col-md-12 p-0 mt-5 pt-5 pt-5">
                                            <div className="row m-0">
                                                <div className="col-3">
                                                    <img className="w-100 s10br6  qpsh" src={QPS1} />
                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100  qpsh s10br6" src={QPS2} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={QPS3} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={QPS4} />

                                                </div>

                                            </div>



                                        </div> */}


                                    </div>


                                    <div className="col-md-6 mt-5 pt-5 pt-5">

                                        <div className="col-md-12 p-0">
                                            <img className="w-100 s10topimg s10br6" src={mehrnakash} />

                                        </div>
                                        {/* <div className="col-md-12 p-0 mt-5 pt-5 pt-5">
                                            <div className="row m-0">
                                                <div className="col-3">
                                                    <img className="w-100 s10br6  qpsh" src={mehrnakash1} />
                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100  qpsh s10br6" src={mehrnakash2} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={mehrnakash3} />

                                                </div>
                                                <div className="col-3">
                                                    <img className="w-100 s10br6 qpsh " src={mehrnakash4} />

                                                </div>

                                            </div>



                                        </div> */}


                                    </div>

                                    <div className="col-md-6 mt-5 pt-5 pt-5  ">
                                    <p className="poppins_semibold s10heading">MEHR QURANIC NASKH FONT</p>
                                        <p className="poppins_regular s10text">Mehr Naskh Quranic font calligraphy done by Janab Nasrullah Mehr and font is designed by Qari Akram Muhammadi, who have developed before Muhammadi Quranic font. Now you can get composed Quran and Tafaseer in Mehr Quranic Naskh font, it is most recent extended bold font, especially design for publishing of Holy Quran and other Islamic books. It is fully compatible with Adobe InDesign, illustrator and all Windows software’s.</p>

                                        <p className="poppins_semibold s10heading mt-3">ABOUT SOFTWARE</p>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Product License</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Ahmedgrafics license & copyright product</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Supports</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">In Windows <b>(Online and Dongle Support)</b></p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Includes</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">License and 24 hours’ support</p>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Develop by</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Ahmedgrafics</p>
                                            </div>

                                        </div>
                                        
                                        <div className="row ">
                                            <div className="col-3">
                                                <p className="poppins_semibold s10text">Status</p>

                                            </div>
                                            <div className="col-9">
                                                <p className="poppins_regular s10text">Developer & Distributor in Pakistan</p>
                                            </div>

                                        </div>

                                        <div className="text-right">
                                            {/* <button className="s10btn">Get a Qoute</button> */}

                                        </div>
                                    </div>


                                </div>
                                <img className="bgright" src={backgroundRight} />

                            </div>




                        </div>

                    </div>

                    <Footer />
                </div>

            </>

        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
Publishing.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Publishing);

