import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TypeWriter from 'typewriter-react'
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import OpenBookImg from '../assets/Images/Section2/Carosol/OpenBook.svg'
import check from '../assets/Images/Section2/Carosol/check.png'
import AlQuran from '../assets/Images/Section2/Carosol/AlQuran.svg'
import Application from '../assets/Images/Section2/Carosol/Application.svg'
import Cart from '../assets/Images/Section2/Carosol/Cart.svg'

import android from '../assets/Images/Section2/Carosol/android.svg'
import ebook from '../assets/Images/Section2/Carosol/ebook.svg'
import fontsdev from '../assets/Images/Section2/Carosol/fontsdev.svg'
import wordpress from '../assets/Images/Section2/Carosol/wordpress.svg'

import Section2LeftImg from '../assets/Images/Section2/left.png'
import Section2RightImg from '../assets/Images/Section2/right.png'




class ThirdComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {
     
   }
  

 

   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
      const responsive = {
        superLargeDesktop: {
           // the naming can be any, depends on you.
           breakpoint: { max: 4000, min: 3000 },
           items: 4
        },
        desktop: {
           breakpoint: { max: 3000, min: 1024 },
           items: 4
        },
        tablet: {
           breakpoint: { max: 1024, min: 464 },
           items: 2
        },
        mobile: {
           breakpoint: { max: 464, min: 0 },
           items: 1
        }
     };
      return (
        <>

<div className="Section1">
                  <div className="container">
                     <div className="col-md-12">
                        <div className="col-md-12 text-center">
                           <div className="leftBorder"></div>
                           <p className="Section3HEading">OUR SERVICES</p>
                           <div className="rightBorder"></div>
                        </div>

                         <div className="col-md-12">
                        <div className="col-md-12 text-center">
                        <div className="Section3_HEading3">

                           <TypeWriter
                              input={[
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "BOOK PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "QURA'N PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "UI UX DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "ECOMMERCE SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "E-BOOK DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "ANDROID APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "FONT DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 "REACT SOLUTION",
                                 { "pause": 8 },
                                 { "delete": 18 },
                                 ]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                                                     </div>
                                                     </div>
                       
                        <div className="col-md-12 mt-5 pb-5 mb-5 ">
                           <img className="Section3LeftImg" src={Section2LeftImg} />

                           <MultiCarousel autoPlay infiniteLoop responsive={responsive}>
                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                              <div className=" mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={OpenBookImg} />

                                       <p className="caraosolHeading">BOOK<br></br> PUBLISHING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={AlQuran} />

                                       <p className="caraosolHeading">QURA'N <br></br> PUBLISHING </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Layouts & Formatting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Printing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Delivering</p>



                                    </div>

                                    <button className="publisherCardBtn">QURA'N PUBLISHING</button>
                                 </div> 

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Application} />

                                       <p className="caraosolHeading">UI UX<br></br> DESIGNING</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Use Cases</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Prototyping</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Design Pattern</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />UI Screening</p>



                                    </div>

                                    <button className="publisherCardBtn">UI UX SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={Cart} />

                                       <p className="caraosolHeading">ECOMMERCE<br></br>SOLUTIONS</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Woo Commerce</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Management </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Payment Integration </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Elegant Layouts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customer Support</p>



                                    </div>

                                    <button className="publisherCardBtn">ONLINE SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={ebook} />

                                       <p className="caraosolHeading">E-BOOK<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />.EPUB Generation</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Type Setting</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />PDF Supported</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />E-Book Conversion</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Publish Anywhere</p>



                                    </div>

                                    <button className="publisherCardBtn">E-BOOK SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={android} />

                                       <p className="caraosolHeading">ANDROID <br></br> APPLICATION </p>

                                       <p className="carosolText"><img className="mr-3" src={check} />UI UX Principles</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Customize Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Furnished App</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />API Integeration</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Database Connectivity</p>



                                    </div>

                                    <button className="publisherCardBtn">MOBILE APP SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={fontsdev} />

                                       <p className="caraosolHeading">FONT<br></br> DEVELOPMENT</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Caligraphy and Styling</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Keyboard Designing</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Urdu Nastaliq Fonts</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />True Type Font Conversion</p>



                                    </div>

                                    <button className="publisherCardBtn">FONT SOLUTION</button>
                                 </div>

                              </div>
                              <div className="mt-1 pl-3 pr-3">
                                 <div className="publishing-Card">
                                    <div className="p-4">
                                       <img src={wordpress} />

                                       <p className="caraosolHeading">REACT<br></br>SOLUTION</p>

                                       <p className="carosolText"><img className="mr-3" src={check} />Custom Designs</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />CRM Application </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Hybrid Apps </p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Responsive Desings</p>
                                       <p className="carosolText"><img className="mr-3" src={check} />Product Designs</p>



                                    </div>

                                    <button className="publisherCardBtn">REACT SOLUTION</button>
                                 </div>

                              </div>

                           </MultiCarousel>

                           <img className="Section3RightImg" src={Section2RightImg} />

                        </div>

                     </div>

                  </div>
               </div>

        </>
       
     
     )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
  
})
ThirdComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ThirdComponent);

