import React, { Component } from 'react';
import './App.css';
import AppNavigation from '../pages/navigation/AppNavigation';

import { Provider } from 'react-redux';
import store from '../store/store';

 
import '../assets/styles/base/_style.scss'
import '../assets/styles/base/_Dashboard.scss'
import '../assets/styles/base/_Section2.scss'
import '../assets/styles/base/_Section3.scss'
import '../assets/styles/base/_Section4.scss'
import '../assets/styles/base/_Section5.scss'
import '../assets/styles/base/_Section6.scss'
import '../assets/styles/base/_Section8.scss'
import '../assets/styles/base/_Section9.scss'
import '../assets/styles/base/_Section10.scss'
import '../assets/styles/base/_carosal.scss'
import '../assets/styles/base/_Footer.scss'
import '../assets/styles/base/_OurWorkAndroid.scss'
import '../assets/styles/base/_UIUX.scss'
import '../assets/styles/base/_Logos.scss'
import '../assets/styles/base/_modal.scss'




















class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <AppNavigation />
            </Provider>
        );
    }
}
export default App;