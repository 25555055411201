import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section4/Group1.png'
import TypeWriter from 'typewriter-react'
import backgroundRight from '../assets/Images/Section4/Ellipse3.png'

//section6
import Section6book1 from '../assets/Images/Section6/1.png'
import Section6book2 from '../assets/Images/Section6/2.png'
import Section6book3 from '../assets/Images/Section6/3.png'
import Section6book4 from '../assets/Images/Section6/4.png'
import Section6book5 from '../assets/Images/Section6/5.png'
class SixthComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {
     
   }
  

 

   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
     
      return (
        <>
  <div id="publishing" className="Section1 Section6Container">
                  <div className="container">
                     <div className="col-md-12 p-0">
                        <img className="S6bgleft" src={backgroundleft} />
                        <div className="row">
                           <div className="col-md-6 mt-5">
                              <p className="HEading">AHMEDGRAFICS & <br></br>COMPUTER SERVICES</p>
                              <p className="HEading2">WORKING SINCE 1998</p>
                              <div className="Section4HEading3">

                           <TypeWriter
                              input={[
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "PUBLISHING",
                                 { "pause": 8 },
                                 { "delete": 10 },
                                 "TYPING",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "LAYOUTING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "FORMATING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STYLING",
                                 { "pause": 8 },
                                 { "delete": 7 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINT READY FILE",
                                 { "pause": 8 },
                                 { "delete": 16 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                              <div className="col-md-12 mt-5">
                                 <div className="row mt-5">
                                    <div className="col-md-5"></div>
                                    <div className="col-md-7">

                                       <button className="S6-btn">01</button>
                                       <label className="btnLabel">LAYOUT &<br></br>FORMATING</label>
                                    </div>

                                 </div>


                                 <div className="row mt-5">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9">

                                       <button className="S6-btn">02</button>
                                       <label className="btnLabel">PROOF READING  &<br></br>PRINT READY COPY</label>

                                    </div>

                                 </div>

                                 <div className="row mt-5">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-11">

                                       <button className="S6-btn">03</button>
                                       <label className="btnLabel">PRINTING &<br></br>DELIVERING</label>

                                    </div>

                                 </div>

                              </div>

                           </div>
                           <div className="col-md-6 text-center mt-3">
                              <img className="s6book1" src={Section6book1} />
                              <img className="s6book2" src={Section6book2} />
                              <img className="s6book3" src={Section6book3} />
                              <img className="s6book4" src={Section6book4} />
                              <img className="s6book5" src={Section6book5} />

                           </div>
                        </div>
                        <img className="S6bgright" src={backgroundRight} />

                     </div>

                  </div>

               </div>
              


        </>
       
     
     )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
  
})
SixthComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(SixthComponent);

