import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import TypeWriter from 'typewriter-react'

import UiImg from '../assets/Images/Section4/UiImg.png'
import Section4SideBar1 from '../assets/Images/Section4/Sidebar/1.svg'
import Section4SideBar2 from '../assets/Images/Section4/Sidebar/2.svg'
import Section4SideBar3 from '../assets/Images/Section4/Sidebar/3.svg'
import Section4SideBar4 from '../assets/Images/Section4/Sidebar/4.svg'
import Section4SideBar5 from '../assets/Images/Section4/Sidebar/5.svg'
import RhConsult from '../assets/Images/Section4/RhConsult.png'
import TutorBay from '../assets/Images/Section4/tutorbay.png'
import backgroundleft from '../assets/Images/Section4/leftlogo.png'
import backgroundRight from '../assets/Images/Section4/rightlogo.png'
class ThirdComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {
     
   }
  

 

   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
     
      return (
        <>

<div id="Development" className="Section1 Section4Container">
                  <div className="container">
                     <div className="col-md-12">
                        <img className="S4bgleft" src={backgroundleft} />
                        <div className="row">
                           <div className="col-md-5 mt-5">
                              <p className="HEading">AHMEDGRAFICS & <br></br>COMPUTER SERVICES</p>
                              <p className="HEading2">WORKING SINCE 1998</p>
                              <div className="Section4HEading3">

                           <TypeWriter
                              input={[
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "MOBILE APPS",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "WEB APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "WORDPRESS",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "WOOCOMMERCE",
                                 { "pause": 8 },
                                 { "delete": 11 },
                                 "REACT",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "UI UX DEVELOPMENT",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 ".NET APPLICATION",
                                 { "pause": 8 },
                                 { "delete": 17 },
                           ]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>

                              <div className="bottomDiv">
                                 <div className="row m-0">
                                    <p className="Section4HEading4 mb-0">PORTFOLIO </p>
                                    <div className="S4Bdr"></div>
                                 </div>
                                 <p className="Section4HEading5">TutorBay | RH3 CONSULTING</p>
                                 <img className=" mr-3 section4Imgs" src={TutorBay} />
                                 <img className="ml-3 mr-3 section4Imgs" src={RhConsult} />
                              </div>

                           </div>
                           <div className="col-md-5 mt-3">
                              <img className="w-100 UIIMG" src={UiImg} />
                           </div>
                           <div className="col-md-2 mt-3">
                              <div className="row">
                                 <div className="col-md-12 col-4">

                                    <div className="ImgBtn">
                                       <img className="ImgBtnImg" src={Section4SideBar1} />
                                    </div>
                                 </div>

                                 <div className="col-md-12 col-4">


                                    <div className="ImgBtn">
                                       <img className="ImgBtnImg" src={Section4SideBar2} />
                                    </div>
                                 </div>


                                 <div className="col-md-12 col-4">

                                    <div className="ImgBtn">
                                       <img className="ImgBtnImg" src={Section4SideBar3} />
                                    </div>
                                 </div>


                                 <div className="col-md-12 col-4">

                                    <div className="ImgBtn">
                                       <img className="ImgBtnImg" src={Section4SideBar4} />
                                    </div>
                                 </div>


                                 <div className="col-md-12 col-4">

                                    <div className="ImgBtn">
                                       <img className="ImgBtnImg" src={Section4SideBar5} />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <img className="S4bgright" src={backgroundRight} />

                     </div>

                  </div>

               </div>


        </>
       
     
     )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
  
})
ThirdComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ThirdComponent);

