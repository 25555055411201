import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section1/backgroundlreft.svg'

import backgroundRight from '../assets/Images/Section1/backgroundRight.svg'


import Section5LeftImage from '../assets/Images/Section7/Left.png'
import Section5RightImage from '../assets/Images/Section7/Right.png'


//section7
import TypeWriter from 'typewriter-react'
import Section7img1 from '../assets/Images/Section7/1.png'
import Section7img2 from '../assets/Images/Section7/2.png'
import Section7img3 from '../assets/Images/Section7/3.png'
import Section7img4 from '../assets/Images/Section7/4.png'
import tick from '../assets/Images/Section7/tick.png'

class SeventhComponent extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],

      };
   }

   componentDidMount() {

   }




   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      return (
         <>
            <div className="Section1">
               <div className="container">
                  <div className="col-md-12">
                     <div className="col-md-12 text-center">
                        <div className="leftBorder"></div>
                        <p className="Section3HEading">TECHNOLOGIES</p>
                        <div className="rightBorder"></div>
                        <div className="Section7_HEading3">

                           <TypeWriter
                              input={[
                                 
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
                                 "COMPOSING",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "TYPE SETTING",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "PROOF READING",
                                 { "pause": 8 },
                                 { "delete": 13 },
                                 "PRINTING",
                                 { "pause": 8 },
                                 { "delete": 8 },
]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                     </div>
                     <div className="col-md-12 Section7Container mt-5 pb-5 mb-5 ">
                        <img className="Section3LeftImg" src={Section5LeftImage} />
                        <div className="row">
                           <div className="col-md-3 mt-3 mb-3">
                              <div className="col-12 p-0">
                                 <div class="container">

                                    <img className="w-100 s9img s7img1" src={Section7img1} />
                                    <p className="poppins_regular s7txt1">SERVICES IN</p>
                                    <p className="poppins_semibold s7txt2">COMPOSING</p>
                                    <div class="overlay">
                                       <p className="poppins_regular overlaytxt1 mb-0">SERVICES IN</p>
                                       <p className="poppins_semibold overlaytxt2">COMPOSING                                                  </p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Urdu Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>English Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Quran Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Qurani Ayat & Dua</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Magazine Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Islamic Calendar</p>
                                       <p className="poppins_medium overlayheading">TOOL IN USE</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InDesign</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InPage</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>PQS</p>
                                       <button className="s7btn">GET A QOUTE</button>

                                    </div>
                                 </div>

                              </div>


                           </div>
                           <div className="col-md-3 mt-3 mb-3">
                           <div className="col-12 p-0">
                                 <div class="container">

                                   
                              <img className="w-100 s7img1" src={Section7img2} />
                              <p className="poppins_regular s7txt1">SERVICES IN</p>
                              <p className="poppins_semibold s7txt2">TYPE SETTING</p>
                                    <div class="overlay">
                                       <p className="poppins_regular overlaytxt1 mb-0">SERVICES IN</p>
                                       <p className="poppins_semibold overlaytxt2">COMPOSING                                                  </p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Urdu Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>English Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Quran Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Qurani Ayat & Dua</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Magazine Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Islamic Calendar</p>
                                       <p className="poppins_medium overlayheading">TOOL IN USE</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InDesign</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InPage</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>PQS</p>
                                       <button className="s7btn">GET A QOUTE</button>

                                    </div>
                                 </div>

                              </div>

                           </div>
                           <div className="col-md-3 mt-3 mb-3">
                            
                              <div className="col-12 p-0">
                                 <div class="container">

                                    <img className="w-100 s9img s7img1" src={Section7img3} />
                                    <p className="poppins_regular s7txt1">SERVICES IN</p>
                                    <p className="poppins_semibold s7txt2">PROOF READING</p>
                                    <div class="overlay">
                                       <p className="poppins_regular overlaytxt1 mb-0">SERVICES IN</p>
                                       <p className="poppins_semibold overlaytxt2">COMPOSING                                                  </p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Urdu Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>English Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Quran Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Qurani Ayat & Dua</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Magazine Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Islamic Calendar</p>
                                       <p className="poppins_medium overlayheading">TOOL IN USE</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InDesign</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InPage</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>PQS</p>
                                       <button className="s7btn">GET A QOUTE</button>

                                    </div>
                                 </div>

                              </div>

                           </div>
                           <div className="col-md-3 mt-3 mb-3">
               
                              <div className="col-12 p-0">
                                 <div class="container">

                                    <img className="w-100 s9img s7img1" src={Section7img4} />
                                    <p className="poppins_regular s7txt1">SERVICES IN</p>
                                    <p className="poppins_semibold s7txt2">PRINTING </p>
                                    <div class="overlay">
                                       <p className="poppins_regular overlaytxt1 mb-0">SERVICES IN</p>
                                       <p className="poppins_semibold overlaytxt2">COMPOSING                                                  </p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Urdu Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>English Typing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Quran Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Qurani Ayat & Dua</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Magazine Composing</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>Islamic Calendar</p>
                                       <p className="poppins_medium overlayheading">TOOL IN USE</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InDesign</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>InPage</p>
                                       <p className="poppins_regular overlaybullets"><img src={tick}/>PQS</p>
                                       <button className="s7btn">GET A QOUTE</button>

                                    </div>
                                 </div>

                              </div>

                           </div>
                        </div>

                        <img className="Section3RightImg" src={Section5RightImage} />

                     </div>

                  </div>

               </div>
            </div>



         </>


      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
SeventhComponent.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(SeventhComponent);

