import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import phone from '../assets/Images/Footer/phone.png'
import clock from '../assets/Images/Footer/clock.png'
import mail from '../assets/Images/Footer/mail.png'
import pin from '../assets/Images/Footer/pin.png'
import zikar from '../assets/Images/Footer/zikar.png'
import alquran from '../assets/Images/Footer/alquran.png'
import inpage from '../assets/Images/Footer/inpage.png'
import map from '../assets/Images/Footer/map.png'

import skype from '../assets/Images/Footer/socialmedia/skype.png'
import messenger from '../assets/Images/Footer/socialmedia/messenger.png'
import instagram from '../assets/Images/Footer/socialmedia/instagram.png'
import linkedin from '../assets/Images/Footer/socialmedia/linkedin.png'
import whatsapp from '../assets/Images/Footer/socialmedia/whatsapp.png'
import facebook from '../assets/Images/Footer/socialmedia/facebook.png'

import profilepdf from '../assets/files/AhmedGrafics and Computer Services - Company Profile.pdf'

import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Footer extends Component {
    static defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
      };
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <div className="FooterContainer">
                <div className="bdtop1"></div>
                <div className="bdtop2"></div>
                <div className="bdtop3"></div>
                <div className="Footer">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <p className="FooterHeading poppins_bold">CONTACT DETAILS</p>
                            <p className="Text  mt-3"><img className="FooterIcon" src={phone} /><label className="ml-2 poppins_regular">(+92) 213-680-1721 | (+92) 331 384 4515 | (+92) 321 234 8257</label></p>
                            <p className="Text  mt-3"><img className="FooterIcon" src={mail} /><label className="ml-2 poppins_regular">info@ahmedgraf.com | info@inpage.com.pk</label></p>
                            <p className="Text  mt-3"><img className="FooterPin" src={pin} /><label className="ml-2 poppins_regular">Office 19, 3rd Floor, Sohni Center, BS 6, FB Area Block 4<br></br>Karimabad, Karachi 75950, Pakistan.</label></p>
                            <p className="Text  mt-3"><img className="footerclock" src={clock} /><label className="ml-2 poppins_regular">Monday to Saturday<br></br>10:00 am to 6:00 pm</label></p>
                            <img className="lastimg" src={inpage} />
                            <img className="lastimg" src={alquran} />
                            <img className="lastimg" src={zikar} />

                        </div>
                        <div className="col-md-2 mt-3">
                            <p className="FooterHeading poppins_bold">About Us</p>
                            <p className="Text  mt-3">
                            <a href="https://drive.google.com/file/d/1SuevUngatU8LTZ44rPtEh6ypgQLm14yS/view?usp=sharing">
                                <label className="ml-2 footer-link poppins_regular">Company Profile</label>
                                </a>
                                </p>
                            <p className="Text  mt-3">
                            <a href="https://www.creatorzsoft.com/">
                                <label className="ml-2 footer-link poppins_regular">CreatorzSoft</label></a></p>
                            <p className="Text  mt-3">
                            <a href="http://tutorbay.pk/">
                                <label className="ml-2 footer-link poppins_regular">TutorBay</label></a></p>
                            <p className="Text  mt-3">
                            <a href="http://bitfitsolutions.com/">
                                <label className="ml-2 footer-link poppins_regular">Bitfitsolutions</label></a></p>
                            <p className="Text  mt-3">
                            <a href="https://littlebookcompany.net/">
                                <label className="ml-2 footer-link poppins_regular">littlebookcompany</label></a></p>
                            <p className="Text  mt-3">
                            <a href="https://store.ahmedgraf.com/">
                                <label className="ml-2 footer-link poppins_regular">Online Store</label></a></p>

                        </div>
                        <div className="col-md-4">
                            {/* <img className="w-100" src={map} /><br></br> */}
                            <div style={{ height: '200px', width: '100%' }}>
        <GoogleMapReact
           bootstrapURLKeys={{ key:"AIzaSyA4t4U0drRk98B1jENi0MeeD5rP59Szywo" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={24.919037360445813}
            lng={67.05352250414859}
            text="AhmedGraf"
          />
        </GoogleMapReact>
      </div>
                             <a href="https://meet.google.com/byt-ksdu-vkv">
                            <img className="lastimg1" src={skype}/></a>
                            
                            
                            <a href="https://www.facebook.com/ahmedgraf">
                            <img className="lastimg1" src={messenger}/></a>


                            <a href="https://www.instagram.com/iahmedgraf/">
                            <img className="lastimg1" src={instagram}/></a>

                            <a href="https://www.linkedin.com/company/ahmedgraf">
                            <img className="lastimg1" src={linkedin}/></a>

                            <a href="https://mywa.link/6qidtlq0">
                            <img className="lastimg1" src={whatsapp}/></a>
                            
                            <a href="https://www.facebook.com/ahmedgraf">
                            <img className="lastimg1" src={facebook}/></a>



                        </div>
                    </div>
                </div>


            </div>


        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
Footer.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Footer);

