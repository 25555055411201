import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Logo from '../../assets/Images/Logo.svg'
//Componnet
import alya from '../../assets/Images/OurWork/WooCommerce/alya.png'
import coolmask from '../../assets/Images/OurWork/WooCommerce/coolmask.png'
import icare from '../../assets/Images/OurWork/WooCommerce/icare.png'
import interiochic from '../../assets/Images/OurWork/WooCommerce/interiochic.png'
import kiddomask from '../../assets/Images/OurWork/WooCommerce/kiddomask.png'
import medmask from '../../assets/Images/OurWork/WooCommerce/medmask.png'
import sadiq from '../../assets/Images/OurWork/WooCommerce/sadiq.png'
import littlebook from '../../assets/Images/OurWork/WooCommerce/littlebook.png'

import EHRLogo from '../../assets/Images/OurWork/WooCommerce/EHRLogo.png'
import alyalogo1 from '../../assets/Images/OurWork/WooCommerce/alyalogo1.png'
import medm from '../../assets/Images/OurWork/WooCommerce/medm.png'
import interiochic1 from '../../assets/Images/OurWork/WooCommerce/intero.png'
import icarew from '../../assets/Images/OurWork/WooCommerce/icarew.png'
import backgroundRight from '../../assets/Images/Section1/backgroundRight.svg'
import littlebooklogo from '../../assets/Images/OurWork/Android/littlelogo.png'

import EleventhComponent from '../../component/EleventhComponent'
import Header from '../../component/Header'

import Footer from '../../component/Footer'
class WooCommerce extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className=" ahmedGraphContainer">

                   <Header/>


                    <div className="Section11">
                        <div className="container ourWorkWordpress">
                            <div className="col-md-12 text-center">
                                <div className="leftBorder"></div>
                                <p className="Section3HEading">OUR WORK</p>
                                <div className="rightBorder"></div>
                                <p className="OurWorkSection3_HEading3">WordPress and Woo Commerce</p>
                            </div>

                            <div className="col-md-12 mt-5 ourWorkAndroid">
                                
                            <div className="row mt-5">
                                <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={littlebook} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logo"  src={littlebooklogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">eBook Reader</label> <br></br>
                                                    <label className="heading2">The Little Book Company</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The Little Book Company is a digital platform for bookselling and publishing for you.

We are a platform for publishers and booksellers; we are a bookshop for readers, and we are a publishing house for new e-books and on demand paperback editions. The Little Book Company creates and curates your e-books in many different formats from e-books, to audio, video to paper-back.<br></br>
                                           </p>                                        
                                           <button className="btnn">Visit Website</button>
                                    </div>
                                  
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logo" src={alyalogo1} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Ecommerce Platform</label> <br></br>
                                                    <label className="heading2">Alya.uk</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">ALYA is an online fashion platform aiming to sell the best quality fashion products across globe. ALYA is backed by a leading global supplier of denim jeans Casual Sportswear, headquarters in United Kingdom & factory in Pakistan.</p>
                                        <button className="btnn">Visit Store</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={alya} />

                                    </div>

                                </div>
                             
                                <div className="row mt-5">
                                <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={medmask} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logo"  src={medm} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Ecommerce Platform</label> <br></br>
                                                    <label className="heading2">MedMask.com.pk</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">MedMask is an online face mask selling platform aiming to sell the best quality mask in this COVID-19. Products across Pakistan. MedMask is backed by a leading global supplier of denim jeans Casual Sportswear, headquarters in United Kingdom & factory in Pakistan.</p>
                                        <button className="btnn">Visit Store</button>
                                    </div>
                                  
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                {/* <img className="Logo" src={EHRLogo} /> */}

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Ecommerce Platform</label> <br></br>
                                                    <label className="heading2">CoolMask.pk</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">Coolmask is an online mask show case platform aiming to show the mask products across Pakistan. Coolmask is backed by a leading global supplier of denim jeans Casual Sportswear, headquarters in United Kingdom & factory in Pakistan.</p>
                                        <button className="btnn">Visit Store</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={coolmask} />

                                    </div>

                                </div>
                             
                                <div className="row mt-5">
                                <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={kiddomask} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                {/* <img className="Logo"  src={EHRLogo} /> */}

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Ecommerce Platform</label> <br></br>
                                                    <label className="heading2">Kiddo-Mask.pk</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">Kiddo Mask is an online mask show case platform aiming to show the kids mask products across Pakistan. Kiddo Mask is backed by a leading global supplier of denim jeans Casual Sportswear, headquarters in United Kingdom & factory in Pakistan.</p>
                                        <button className="btnn">Visit Store</button>
                                    </div>
                                  
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logo" src={interiochic1} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Interior Design Web</label> <br></br>
                                                    <label className="heading2">InterioChic.com</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">InterioChic is a platform aiming to show the interior / exterior design houses, cooperate office, shop and rooms, across Pakistan. InterioChic is a leading startup in the field of Interior Designing run by Founder Tooba Syed a graduate student of Indus University.</p>
                                        <button className="btnn">Visit Web</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={interiochic} />

                                    </div>

                                </div>
                             
                                <div className="row mt-5">
                                <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={sadiq} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                {/* <img className="Logo"  src={EHRLogo} /> */}

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Sehba Akhter</label> <br></br>
                                                    <label className="heading2">SehbaAkhter.com</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">Sehba Akhter, a poet, lyrics writer and author of various books. This web site is build in the purpose to make their all collections and books under a one ambarella by his son Nadeem Akhter. </p>
                                        <button className="btnn">Visit Store</button>
                                    </div>
                                  
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logo" src={icarew} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Mobile Repairing Web</label> <br></br>
                                                    <label className="heading2">iCareSmartTech.com</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">iCare mobile skin clear coat protection smart phone high-quality skin protection. I care provide a multiple kinds of film material with high quality to meet your demand. It is a platform help to know about iCare and its services and also customer can contact and query. </p>
                                        <button className="btnn">Visit Web</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={icare} />

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <EleventhComponent/>

                    <Footer />
                </div>

            </>

        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
WooCommerce.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(WooCommerce);

