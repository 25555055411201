import React, { Component } from 'react';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Logo from '../../assets/Images/Logo.svg'
//Componnet
import tutorbaylogo from '../../assets/Images/OurWork/Android/tutorbaylogo.png'

import Tutorbay from '../../assets/Images/OurWork/Android/Tutorbay.png'
import UrduBookLogo from '../../assets/Images/OurWork/Android/UrduBookLogo.png'
import littlebooklogo from '../../assets/Images/OurWork/Android/littlelogo.png'
import littlebook from '../../assets/Images/OurWork/Android/littlebook.png'
import UrduBook from '../../assets/Images/OurWork/Android/UrduBook.png'
import dewanghalib from '../../assets/Images/OurWork/Android/dewanghalib.png'
import dewanghalibb from '../../assets/Images/OurWork/Android/dewanghalibb.png'
import smarttutionlogo from '../../assets/Images/OurWork/Android/smarttutionlogo.png'
import smarttution from '../../assets/Images/OurWork/Android/smarttution.png'

import quran from '../../assets/Images/OurWork/Android/quran.png'
import quranlogo from '../../assets/Images/OurWork/Android/quranlogo.png'
import Ehr from '../../assets/Images/OurWork/Android/EHR.png'
import EhrLogo from '../../assets/Images/OurWork/Android/EHRLogo.png'
import paksearch from '../../assets/Images/OurWork/Android/paksearch.png'
import btm1 from '../../assets/Images/OurWork/Android/1.png'
import btm2 from '../../assets/Images/OurWork/Android/2.png'
import btm3 from '../../assets/Images/OurWork/Android/3.png'
import btm4 from '../../assets/Images/OurWork/Android/4.png'
import EleventhComponent from '../../component/EleventhComponent'



import Header from '../../component/Header'
import Footer from '../../component/Footer'
class Android extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className=" ahmedGraphContainer">


                    <Header />

                    <div className="Section11">
                        <div className="container">
                            <div className="col-md-12 text-center">
                                <div className="leftBorder"></div>
                                <p className="Section3HEading">OUR WORK</p>
                                <div className="rightBorder"></div>
                                <p className="OurWorkSection3_HEading3">ANDROID</p>
                            </div>

                            <div className="col-md-12 mt-5 ourWorkAndroid">
                                <div className="row">

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={littlebook} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="logoions" src={littlebooklogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">eBook Reader</label> <br></br>
                                                    <label className="heading2">The Little Book</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The Little Book Company is a digital platform for bookselling and publishing for you.

We are a platform for publishers and booksellers; we are a bookshop for readers, and we are a publishing house for new e-books and on demand paperback editions. The Little Book Company creates and curates your e-books in many different formats from e-books, to audio, video to paper-back.<br></br>
                                           </p>
                                        <button className="btnn">Available in Google Store</button>
                                    </div>

                                </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img src={tutorbaylogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Tutor Finder App</label> <br></br>
                                                    <label className="heading2">TutorBay</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">Connecting peer to peer on demand learning session between students and teacher for their after school tuition and skill based training through mobile application. It is basically a tutor directory where student can find multiple tutors at one place and can hire any tutor they want (fiver for tutors).</p>
                                        <button className="btnn">Under Development</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={Tutorbay} />

                                    </div>

                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={UrduBook} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img src={UrduBookLogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Urdu Book App</label> <br></br>
                                                    <label className="heading2">Complete Ghalib</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">This application contains four famous books of Ghalib, a book reader application allows user to read book using native android application via smart phone. Books the app contains are follow<br></br>
                                            1: Wardat e Ghalib<br></br>
                                            2: Khutoot e Ghalib<br></br>
                                            3: Dewan e Ghalib (with total 2622 ashaar)<br></br>
                                            4: Sharah Dewan e Ghalib"</p>
                                        <button className="btnn">Available in Google Store</button>
                                    </div>

                                </div>

                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img src={dewanghalib} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Urdu Book App</label> <br></br>
                                                    <label className="heading2">Dewan e Ghalib</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">Ghalibs first Deewan was published in 1841. This world rediscovered Ghalib 100 years after his death, that was 1968. Saleem Aazer has done tremendous research to compile this new edition. This Deewan e Ghalib has 2622 ashaar.</p>
                                        <button className="btnn">Available in Google Store</button>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={dewanghalibb} />

                                    </div>

                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={smarttution} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img src={smarttutionlogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Urdu Book App</label> <br></br>
                                                    <label className="heading2">Complete Ghalib</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The basic need of the application is to provide platform to student and tutor. It’s necessary to have an application for searching a tutor by desire requirement. Also it will overcome the problem of unemployment in the country. The application will act as a bridge between these two users. They can communicate within the application. The use of GPS and Recommender system will bring comfort by searching a tutor near to location. Meanwhile the rating of individual tutor will benefits a tutor for a new jobs convenience, as these rating are based on student experience.<br></br></p>
                                        <button className="btnn">Available in Google Store</button>
                                    </div>

                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img src={quranlogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Quran Reader App</label> <br></br>
                                                    <label className="heading2">Asan Quran</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The Quran app, based on Quranic Ayat and Surah. This app was design to help people to read Quran and search the versus of Quran. Where as this application uses both Urdu and English translation of Quran.</p>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={quran} />

                                    </div>

                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={Ehr} />

                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">
                                                <img className="Logos" src={EhrLogo} />

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Health Care System</label> <br></br>
                                                    <label className="heading2">Electronic Health Record</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The application is based over a research experiment build over cloud solution to maintain patient record via mobile application to see their overall statistics and report via mobile application.<br></br></p>
                                        {/* <button className="btnn">Available in Google Store</button> */}
                                    </div>

                                </div>
                                <div className="row mt-5 mb-5">
                                    <div className="col-md-6 mt-5">
                                        <div className="row">
                                            <div className="vertical-center">

                                            </div>
                                            <div>
                                                <p className="mainHeading">
                                                    <label className="heading">Research Submission System</label> <br></br>
                                                    <label className="heading2">Pak Research</label>
                                                </p>
                                            </div>

                                        </div>

                                        <p className="Text">The application is based over a research experiment build over block chain solution to maintain researchers record via mobile application to see their overall process of publication via mobile application.</p>
                                    </div>
                                    <div className="col-md-6 mt-5 text-center">
                                        <img className="w-100" src={paksearch} />

                                    </div>

                                </div>
                                <div className="text-center mt-5 mb-5">
                                    <p className="mainHeading">
                                        <label className="heading">Research Submission System</label> <br></br>
                                        <label className="heading2">Pak Research</label>
                                    </p>
                                    <div className="row">
                                        <div className="col-md-3 mt-4">
                                            <img className="w-100" src={btm1} />
                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <img className="w-100" src={btm2} />

                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <img className="w-100" src={btm3} />

                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <img className="w-100" src={btm4} />

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <EleventhComponent />
                        </div>

                    </div>

                    <Footer />
                </div>

            </>

        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
Android.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Android);

