import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Logo from '../assets/Images/Logo.svg'
import menu from '../assets/Images/menu.svg'
import close from '../assets/Images/Modal/close.png'
import modallogo from '../assets/Images/Modal/Logo.png'
import phone from '../assets/Images/Modal/phone.png'
import modalicon2 from '../assets/Images/Modal/2.png'
import modalicon3 from '../assets/Images/Modal/3.png'
import modalicon4 from '../assets/Images/Modal/4.png'



import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import publish from '../assets/Images/publish.png'
import ui from '../assets/Images/ui.png'
import web from '../assets/Images/web.png'
import app from '../assets/Images/app.png'

class Header extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],
         showModal: false,


      };
   }

   componentDidMount() {

   }

   toggleModal = () => {
      this.setState({
         showModal: !this.state.showModal
      })
   }


   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      return (
         <div className={this.props.page === 1 ? "col-md-12 pl-0 pr-0 Header pt-3 " : "col-md-12 pl-0 pr-0 Header pt-3 "} >

            <Modal


               dialogClassName="col-xl-12"
               show={this.state.showModal}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
            >
               <div className="  modal-body">
                  <div className="container">
                     <div className="col-md-12">
                        <div className="row">
                           <div className="col-md-6">
                              <p className="poppins_regular modalText mb-0">FOR ANY QUERRY AND IFORMATION</p>
                              <p className="poppins_bold mb-0 modalHeading">CONTACT US</p>
                              <div className="inputContainer">
                                 <input className="modalInput" placeholder="Full Name"></input>
                                 <input className="modalInput" placeholder="Email Adress"></input>
                                 <input className="modalInput" placeholder="Contact Details"></input>
                                 <textarea className="modalInput1" placeholder="Details / Querry"></textarea>

                              </div>
                           </div>
                           <div className="col-md-6 text-right">
                              <div className="col-12">
                                 <img className="closebtn" src={close} onClick={() => this.setState({ showModal: false })}/>
                              </div>
                              <div className="col-12">
                                 <img className="ModalLogo" src={modallogo} />
                                 <div class="col-12">

                                    <label className="poppins_regular modalText1">(+92) 331 384 4515 | (+92) 321 234 8257(+92) 213-680-1721</label>
                                    <img className="modalIcons" src={phone} />
                                 </div>
                                 <div class="col-12">
                                    <label className="poppins_regular modalText1">info@ahmedgraf.com | info@inpage.com.pk</label>
                                    <img className="modalIcons" src={modalicon2} />
                                 </div>
                                 <div class="col-12">
                                    <label className="poppins_regular modalText1">Office 19, 3rd Floor, Sohni Center, BS 6, FB Area Block 4 <br></br>Karimabad, Karachi 75950, Pakistan</label>
                                    <img className="modalIcons " src={modalicon3} />
                                 </div>
                                 <div class="col-12">
                                    <label className="poppins_regular modalText1">Monday to Saturday<br></br>10:00 am to 6:00 pm</label>
                                    <img className="modalIcons" src={modalicon4} />
                                 </div>
                                 <div class="col-12">
                                    <button class="modalBtn">Send Querry</button>
                                 </div>


                              </div>


                           </div>


                        </div>
                     </div>

                  </div>



               </div>


            </Modal>



            <div className="row">
               <div className="col-md-3 Vertical_center LOGOCENTER">
                  <Link to="/">

                     <img className="Logo-Img" src={Logo} />
                  </Link>
               </div>
               <div className="col-md-9 Vertical_center">
                  <div className="row">
                     <div className="header-contain">
                        <Link to="/">

                           <p className="poppins_medium headerActive f_14" >Overview</p>
                        </Link>
                     </div>

                     <div className="header-contain">
                     <a href="#Development">

                        <p className="poppins_medium headernotActive f_14" >Development</p>
                        </a>
                     </div>
                     <div className="header-contain">
<a href="#Design">

                        <p className="poppins_medium headernotActive f_14" >Design</p>
                        </a>

                     </div>
                     <div className="header-contain">
                     <a href="#publishing">


                        <p className="poppins_medium headernotActive f_14" >Publishing </p>
</a>
                     </div>
                     <div className="header-contain">
                        <Link to="/product">
                           <p className="poppins_medium headernotActive f_14" >Products</p>
                        </Link>
                     </div>
                     <div className="header-contain">
                        <a href="https://store.ahmedgraf.com/">
                           <p className="poppins_medium headernotActive f_14" >Our Store</p>
                        </a>
                     </div>
                     <div className="header-contain">

                        {/* <p className="poppins_medium headernotActive f_14" >Our Work</p> */}
                        <p className="mb-0  poppins_medium headernotActive f_14" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" data-toggle="collapse">
                           Our Work</p>

                     </div>
                     <div className="header-contain-btn">
                        <button className="contactBtn" onClick={() => this.setState({ showModal: true })}>Contact Us</button>
                     </div>
                  </div>
               </div>
            </div>
            <div class="collapse" id="collapseExample">
               <div class="card card-body">
                  <div className="container">
                     <div className="col-md-3 text-center">
                        <Link to="/publishingwork">

                           <p className="navText"><img className="CardTextImg" src={publish} />PUBLISHING WORK</p>
                        </Link>

                     </div>
                     <div className="col-md-3 text-center">

                        <Link to="/applicationwork">
                           <p className="navText"><img className="CardTextImg" src={publish} />APPLICATION WORK</p>
                        </Link>
                     </div>
                     <div className="col-md-3 text-center">

                        <Link to="/websitedevelopment">
                           <p className="navText"><img className="CardTextImg" src={publish} />WEBSITE DEVELOPMENT</p>

                        </Link>
                     </div>
                     <div className="col-md-3 text-center">

                        <Link to="/uiuxdesigning">
                           <p className="navText"><img className="CardTextImg" src={publish} />UI UX  DESIGNING</p>

                        </Link>
                     </div>


                  </div>
               </div>
            </div>
            <div class="navbar-header">
               <img class="navbar-toggle tooglebtnn navbaricon mr-0" data-toggle="collapse" data-target="#myNavbar" src={menu}></img>
            </div>
            <div class="collapse img-w-100 pt-4 pb-4  colpaseDiv " id="myNavbar">
               <ul class="nav navbar-nav img-w-100 ">
                  <Link to="/">
                     <li class=""> <label className="navbar_li_text ">OverView</label></li>
                  </Link>
                  <div className="nav_hr"></div>
                  <Link to="/">
                     <li class=""> <label className="navbar_li_text ">Development</label></li>
                  </Link>

                  <div className="nav_hr"></div>
                  <Link to="/">

                     <li class=""> <label className="navbar_li_text ">Design</label></li>
                  </Link>
                  <div className="nav_hr"></div>
                  <Link to="/publishing">

                     <li class=""> <label className="navbar_li_text ">Publishing</label></li>
                  </Link>
                  <div className="nav_hr"></div>
                  <Link to="/product">

                     <li class=""> <label className="navbar_li_text ">Products</label></li>
                  </Link>
                  <div className="nav_hr"></div>
                  <Link to="/productbrand">
                     <li class=""> <label className="navbar_li_text ">Our Store</label></li>
                  </Link>
                  <div className="nav_hr"></div>
                  <li class=""> <label className="navbar_li_text " href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1" data-toggle="collapse">Our Work</label></li>
                  <div className="nav_hr"></div>
                  <div class="collapse" id="collapseExample1">

                     <Link to="/publishingwork">
                        <li class=""> <label className="navbar_li_text1 "><img className="CardTextImg" src={publish} />PUBLISHING WORK</label></li>
                     </Link>
                     <Link to="/applicationwork">
                        <li class=""> <label className="navbar_li_text1 "><img className="CardTextImg" src={app} />APPLICATION WORK</label></li>
                     </Link>
                     <Link to="/websitedevelopment">
                        <li class=""> <label className="navbar_li_text1 "><img className="CardTextImg" src={web} />WEBSITE DEVELOPMENT</label></li>
                     </Link>
                     <Link to="/uiuxdesigning">
                        <li class=""> <label className="navbar_li_text1 "><img className="CardTextImg" src={ui} />UI UX  DESIGNING</label></li>
                     </Link>

                  </div>





                  <div className="nav_hr"></div>
               </ul>
               <div className="text-center mt-1">

                  <label className="poppins_regular mt-2  navbarcopyright">Design By Muhammad Saad Iqbal | Copyright © Ahmedgraf 2020</label>
               </div>
            </div>

         </div>


      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({

})
Header.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Header);

