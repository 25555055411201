import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backgroundleft from '../assets/Images/Section9/left.png'

import backgroundRight from '../assets/Images/Section9/right.png'

import TypeWriter from 'typewriter-react'

import Section5LeftImage from '../assets/Images/Section9/left.png'
import Section5RightImage from '../assets/Images/Section9/right.png'


//section7

import Section9img1 from '../assets/Images/Section9/1.png'
import Section9img2 from '../assets/Images/Section9/2.png'
import Section9img3 from '../assets/Images/Section9/3.png'
import Section9img4 from '../assets/Images/Section9/4.png'
import Section9img5 from '../assets/Images/Section9/5.png'

import btm from '../assets/Images/Section9/btm.png'
import btm2 from '../assets/Images/Section9/btm2.png'

import Section7img3 from '../assets/Images/Section7/3.png'
import Section7img4 from '../assets/Images/Section7/4.png'


class NineSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            newBookList: [],

        };
    }

    componentDidMount() {

    }




    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className="Section1">
                    <div className="container">
                        <div className="col-md-12 Section9Container">
                            <div className="col-md-12 text-center">
                                <div className="leftBorder"></div>
                                <p className="Section3HEading">DESIGNING</p>
                                <div className="rightBorder"></div>
                                <div className="Section9_HEading3">

                           <TypeWriter
                              input={[
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
                                 "STORY DESIGNING",
                                 { "pause": 8 },
                                 { "delete": 15 },
                                 "CHARACHTER MAKING",
                                 { "pause": 8 },
                                 { "delete": 17 },
                                 "TITLE DESIGN",
                                 { "pause": 8 },
                                 { "delete": 12 },
                                 "LOGOS",
                                 { "pause": 8 },
                                 { "delete": 5 },
                                 "BANNER",
                                 { "pause": 8 },
                                 { "delete": 6 },
                                 "VISITING CARDS",
                                 { "pause": 8 },
                                 { "delete": 14 },
                                 "3D DESIGN",
                                 { "pause": 8 },
                                 { "delete": 9 },
]}

                              speed={75}
                              cursor={true}
                              cursorChar="|"
                              className="HEading3"
                           />
                                                     </div>
                            </div>
                            <div className="col-md-12 mt-5 pb-5 mb-5 ">
                                <img className="S9bgleft" src={Section5LeftImage} />
                                <div className="row">
                                    <div className="col-md-3 mt-3 mb-3">
                                        <div className="col-md-12 p-0">
                                            <div class="container">
                                                <img className="w-100 s9img s9img1" src={Section9img1} />
                                                <div class="overlay">
                                                    <p className="poppins_regular overlaytxt1">STORY BOOK</p>
                                                    <p className="poppins_semibold overlaytxt2">PAKISTANI<br></br>TRADITIONAL<br></br>FAMILY<br></br>                                                    </p>
                                                    <p className="poppins_regular overlaytxt1">CHAPTER 01 - HELPING FAMILY</p>
                                                    <img className="s9BottomImg2" src={btm} />
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div className="col-md-6 mt-3 mb-3">
                                        <div className="row">
                                            <div className="col-md-6 p-0">
                                                <div class="container">
                                                    <img className="w-100 s9img " src={Section9img2} />
                                                    <div class="overlay">
                                                        <p className="poppins_regular overlaytxt1">STORY BOOK</p>
                                                        <p className="poppins_semibold overlaytxt2">DREAMS POWER</p>
                                                        <img className="s9BottomImg1" src={btm2} />

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-6 p-0">
                                                <div class="container">
                                                    <img className="w-100 s9img" src={Section9img3} />
                                                    <div class="overlay">
                                                        <p className="poppins_regular overlaytxt1">STORY BOOK</p>
                                                        <p className="poppins_semibold overlaytxt2">WHEN I GROW UP</p>
                                                        <img className="s9BottomImg1" src={btm2} />

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-12 mt-3 p-0">
                                                <div class="container">
                                                    <img className="w-100 s9img" src={Section9img5} />
                                                    <div class="overlay">
                                                        <p className="poppins_regular overlaytxt1 mb-0">STORY BOOK</p>
                                                        <p className="poppins_semibold overlaytxt2">PAKISTANI TRADITIONAL FAMILY</p>
                                                        <img className="s9BottomImg" src={btm} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-3 mt-3 mb-3">
                                        <div className="col-md-12 p-0">
                                            <div class="container">
                                                <img className="w-100 s9img s9img1 " src={Section9img4} />
                                                <div class="overlay">
                                                    <p className="poppins_regular overlaytxt1">STORY BOOK</p>
                                                    <p className="poppins_semibold overlaytxt2">EMMA REILLY'S <br></br> SECRET ADMIRE</p>
                                                    <img className="s9BottomImg2" src={btm2} />
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>

                                <img className="S9bgright" src={Section5RightImage} />

                            </div>

                        </div>

                    </div>
                </div>



            </>


        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({

})
NineSection.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(NineSection);

